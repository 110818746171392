import React, { useContext } from "react";
import { Card, Button, Result } from "antd";
import { UserContext } from '../others/UserContext';
import { Main } from "../layouts";
import { Link } from "react-router-dom";
import Colors from '../colors.json'; 

const Home = () => {
  const context = useContext(UserContext);
  return (
    <Main>
      <div className="flex w-full h-screen justify-center items-center">
        {
          (context.routes || []).map((route, index) => {
            return <Link
              key={`${index}`}
              style={{margin:'20px'}}
              to={route.path}>
              <div
                style={{
                  backgroundColor:Colors.primary.background,
                  paddingTop:'30px',
                  paddingBottom:'10px',
                  paddingLeft:'10px',
                  paddingRight:'10px'
                }}
              >
                <Card
                style={{ width: 140 }}
                bordered={false}
                cover={
                  route.bigIcon
                }
                bodyStyle={{
                  backgroundColor: Colors.primary.background,
                  color: Colors.primary.foreground,
                  textAlign: 'center',
                  fontSize: 18,
                  fontWeight: 'bold',
                  marginTop: '-1px',
                  marginInlineStart: '-1px',
                  marginInlineEnd: '-1px'
                }}
              >
                {
                  route.label
                }
              </Card>
              </div>
            </Link>
          })
        }
      </div>
    </Main>
  );
};

export default Home;
