import React from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom"
import ViewLogin from '../pages/Login'
import useSite from "./useSite";
import PropTypes from "prop-types"

/**
 * Hook para la autenticación
 * @param {string} site     - Site de trabajo ('develop', 'production')
 */

const useAuth = ({ site }) => {
  let { axios, api, base } = useSite({
    site
  })

  const digestToken = async ({ token, logout }) => {
    try {
      //console.log('digiriendo token', token)
      let data = {};
      let resp = await axios.post(`${api}_checkToken`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      let fields = resp.data.token_payload;
      data.authed = true;
      data.token = token;
      data.user = fields.sub;
      data.databases = fields.auds;
      data.roles = fields.roles;
      data.name = fields.name;
      data.user_id = fields.user_id;
      return data;
    }
    catch (err) {
      logout()
    }
  }

  const checkToken = async ({ token, setData, logout }) => {
    //console.log('chequeando token',token)
    if (token) {
      let data = await digestToken({ token, logout });
      data.loading = false;
      data.logout = logout;
      setData(prev => ({
        ...prev,
        ...data
      }));
    }
    else {
      let data = {
        loading: false,
        logout
      }
      setData(prev => ({
        routes:prev.routes,
        ...data
      }));
    }
  };

  const AuthLogin = (props) => {
    let { logout, setData, ...other } = props;
    const { state } = useLocation();
    const navigate = useNavigate();

    ;
    const getToken = async ({ password, user }) => {
      try {
        //console.log('obteniendo token',user,password)
        let resp = await axios.post(`${api}_login`, {}, {
          auth: {
            username: user,
            password: password
          }
        })
        // Fields
        let token = resp.data.token;
        let data = await digestToken({ token, logout });
        data.token = token;
        data.logout = logout;
        /*let data = {
          logout,
          token: resp.data.token
        }*/
        setData(prev => ({
          ...prev,
          ...data
        }));
        let path = state?.path || "/";
        if (path == "/login") {
          path = "/";
        }
        //console.log('path',path)
        navigate(path, { replace: true });
      }
      catch (err) {
        //debugger;
        logout()
      }
    }

    return <ViewLogin
      {...other}
      onClick={getToken}
    />

  }

  const RequireAuth = (props) => {
    let { authed, children } = props;
    const location = useLocation();
    return authed
      ? children
      : <Navigate
        to="/login"
        replace
        state={{ path: location.pathname }}
      />;
  }

  return { digestToken, checkToken, AuthLogin, RequireAuth };
};

useAuth.defaultProps = {
  site: "production"
};

useAuth.propTypes = {
  /** Site de  trabajo ('develop', 'production' ) */
  site: PropTypes.string,
};


export default useAuth;