import { rgb, degrees } from 'pdf-lib';
import { drawText, splitText, getTextHeight } from 'canvas-txt'

export const UUID = (a) => {
  return a
      ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
      : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, UUID)
}

class Element {

  static hex2decPartial(v) {
    return parseInt(v, 16) / 255.0
  };

  static hex2dec(v) {
    return parseInt(v, 16)
  };

  static hexToRGB(hexColor) {
    return rgb(Element.hex2decPartial(hexColor.slice(0, 2)), Element.hex2decPartial(hexColor.slice(2, 4)), Element.hex2decPartial(hexColor.slice(4)))
  }

  static hexToRGBAString(hexColor, opacity) {
    return `rgba(${Element.hex2dec(hexColor.slice(0, 2))},${Element.hex2dec(hexColor.slice(2, 4))},${Element.hex2dec(hexColor.slice(4))},${opacity})`
  }

  static pointToObject(p, height) {
    return { x: p[0], y: height - p[1] }
  }

  static getSquaredDistance(a, b) {
    let ab = [b[0] - a[0], b[1] - a[1]];
    return ab[0] * ab[0] + ab[1] * ab[1];
  }

  static inRect( a, b, p, rotation ) {
    let rb = Element.rotateLocalPoint( [b[0]-a[0], b[1]-a[1]], -rotation )
    let rp = Element.rotateLocalPoint( [p[0]-a[0], p[1]-a[1]], -rotation )

    let [p1, p2] = Element.getRect(a, [a[0]+rb[0], a[1]+rb[1]]);
    if( (rp[0]+a[0]) >=p1[0] && (rp[0]+a[0])<=p2[0] 
      && (rp[1]+a[1])>=p1[1] && (rp[1]+a[1])<=p2[1] ) {
        return true;
      }
    return false;
  }

  static getRect(a,b) {
    let p1 = [Math.min(a[0],b[0]), Math.min(a[1],b[1]) ];
    let p2 = [Math.max(a[0],b[0]), Math.max(a[1],b[1]) ];
    return [ p1, p2 ];
  }

  static rotateLocalPoint(p, rotation) {
    let a = rotation*Math.PI/180;
    let sina = Math.sin(a);
    let cosa = Math.cos(a);
    return [
       p[0]*cosa + p[1]*sina,
       -p[0]*sina + p[1]*cosa
    ]
  }

  static rotatePointPage(p, rotation, canvas) {
    if( rotation ===180 ) return [ canvas.width - p[0], canvas.height - p[1] ];
    if( rotation === 90 ) return [ canvas.width - p[1], p[0]];
    if( rotation === 270 ) return [ p[1], canvas.height - p[0]];
    return [p[0], p[1]]
  }

  static desrotatePointPage(p, rotation, canvas) {
    if( rotation === 180 ) return [ canvas.width - p[0], canvas.height - p[1] ];
    if( rotation === 90 ) return [ p[1], canvas.width - p[0]];
    if( rotation === 270 ) return [canvas.height - p[1], p[0]];
    return p
  }

  static getSquaredDistanceToSegment(a, b, p) {

    let ap = [p[0] - a[0], p[1] - a[1]];
    let ab = [b[0] - a[0], b[1] - a[1]];

    let ab2 = ab[0] * ab[0] + ab[1] * ab[1];

    if (ab2 === 0) return Infinity;

    let ap_dot_ab = ap[0] * ab[0] + ap[1] * ab[1];

    let t = ap_dot_ab / ab2;
    if (t < 0) {
      return ap[0] * ap[0] + ap[1] * ap[1];
    }
    if (t > 1) {
      let bp = [p[0] - b[0], p[1] - b[1]];
      return bp[0] * bp[0] + bp[1] * bp[1];
    }
    let n = [a[0] + t * ab[0], a[1] + t * ab[1]];
    let np = [p[0] - n[0], p[1] - n[1]];
    return np[0] * np[0] + np[1] * np[1];
  }

  constructor( { points, page, ...attributes} ) {
    this._attr = attributes;
    this._page = page;
    this._points = points;
    this._type = false;
    this._id = UUID();
  }

  get id() {
    return this._id;
  }

  set id(value) {
    this._id = value;
  }

  get page() {
    return this._page;
  }

  get type() {
    return this._type;
  }

  get attributes() {
    return this._attr;
  } 

  get points() {
    return this._points
  }

  setPage( v ) {
    this._page = v;
  }

  setPoint( n, p ) {
    this._points[n] = p;
  }

  moveKeyPoint( n, p) {
    this._points[n] = p;
  }

  setAttribute( name, value ) {
    this._attr[name] = value
  }

  setAttributes( attrs ) {
    for(let name in attrs ) {
      this._attr[name] = attrs[name]
    }
  }

  addPoint( p ) {
    this._points.push(p)
  }

  popPoint() {
    this._points.pop()
  }

  drawCanvas(canvas, attr, rotation) {
  }

  async preDrawPdf(pdfDoc, canvas) {
  }

  postDrawPdf() {
  }

  drawPdf(pdfPage, attr) {
  }

  distanceSquared(pos, canvas) {
    return Infinity
  }

  saveElement() {
    return {
      type: this._type,
      attributes: {
        ...this._attr,
        page: this._page,
        points: this._points
      }
    }
  }
}

class KeyPoints extends Element {
  constructor(attributes) {
    super(attributes);
    this._type = 'keypoints'
  }

  drawCanvas(canvas, attr, rotation) {
    let ctx = canvas.getContext("2d");
    let element = this;
    if (!Array.isArray(element.points) || element.points.length < 1) return;

    ctx.lineJoin = "round";
    ctx.lineCap = "round";
    ctx.strokeStyle = '#0000FF';
    ctx.lineWidth = 1;
    ctx.beginPath();
    for (let i = 0; i < element.points.length; i++) {
      let p = Element.rotatePointPage(element.points[i], rotation, canvas)
      ctx.rect(  p[0] - 2, p[1] - 2, 4, 4);
    }
    ctx.stroke();
  }
}

class Line extends Element {
  constructor(attributes) {
    super(attributes);
    this._type = 'line';
  }

  drawCanvas(canvas, attr, rotation) {
    let ctx = canvas.getContext("2d");
    let element = this;
    if (!Array.isArray(element.points) || element.points.length < 2) return;

    ctx.lineJoin = "round";
    ctx.lineCap = "round";
    let color = Element.hexToRGBAString(element.attributes.color.substring(1), element.attributes.opacity);
    // console.log('color',color)
    ctx.strokeStyle = color;
    ctx.lineWidth = element.attributes.lineWidth;
    ctx.beginPath();
    let p = Element.rotatePointPage(element.points[0], rotation, canvas );
    ctx.moveTo( p[0], p[1] );
    for (let i = 1; i < element.points.length; i++) {
      p = Element.rotatePointPage(element.points[i], rotation, canvas );
      ctx.lineTo( p[0], p[1] );
    }
    ctx.stroke();
  }

  drawPdf(pdfPage, attr) {
    let element = this;
    if (!Array.isArray(element.points) || element.points.length < 2) return;
    let height = pdfPage.getHeight();
    for (let i = 0; i < element.points.length - 1; i++) {
      pdfPage.drawLine({
        start: Element.pointToObject(element.points[i], height),
        end: Element.pointToObject(element.points[i + 1], height),
        thickness: element.attributes.lineWidth,
        color: Element.hexToRGB(element.attributes.color.substring(1)),
        opacity: element.attributes.opacity,
      })
    }
  }

  distanceSquared(pos, canvas) {
    let element = this;
    if (!Array.isArray(element.points) || element.points.length < 2) return Infinity;
    let d = Infinity;
    for (let i = 0; i < element.points.length - 1; i++) {
      let dp = Element.getSquaredDistanceToSegment(element.points[i], element.points[i + 1], pos);
      if (dp < d) d = dp;
    }
    return d;
  }
}

class Circle extends Element {
  constructor(attributes) {
    super(attributes);
    this._type = 'circle';
  }

  drawCanvas(canvas, attr, rotation) {
    let ctx = canvas.getContext("2d");
    let element = this;
    if (!Array.isArray(element.points) || element.points.length < 1) return;
    ctx.lineJoin = "round";
    ctx.lineCap = "round";
    let color = Element.hexToRGBAString(element.attributes.color.substring(1), element.attributes.opacity);
    ctx.strokeStyle = color;
    ctx.lineWidth = element.attributes.lineWidth;
    ctx.fillStyle = null;
    let point = Element.rotatePointPage( element.points[0], rotation, canvas );
    ctx.beginPath();
    ctx.arc(point[0], point[1], element.attributes.radius, 0, 2 * Math.PI)
    ctx.stroke();
  }

  distanceSquared(pos, canvas) {
    let element = this;
    if (!Array.isArray(element.points) || element.points.length < 1) return Infinity;
    let d = Math.sqrt(Element.getSquaredDistance(element.points[0], pos));
    if( element.attributes.withFill ) {
      if( d < element.attributes.radius ) {
        return 0;
      }
    }
    return (d - element.attributes.radius) * (d - element.attributes.radius)
  }
}

class Rectangle extends Element {
  constructor(attributes) {
    super(attributes);
    this._type = 'rect';
    this._attr.withFill = this._attr.withFill || false;
  }

  drawCanvas(canvas, attr, rotation) {
    let ctx = canvas.getContext("2d");
    let element = this;
    if (!Array.isArray(element.points) || element.points.length < 2) return;
    ctx.lineJoin = "round";
    ctx.lineCap = "round";
    let color = Element.hexToRGBAString(element.attributes.color.substring(1), element.attributes.opacity);
    ctx.strokeStyle = color;
    let fillColor = Element.hexToRGBAString(element.attributes.fillColor.substring(1), element.attributes.opacity);
    ctx.fillStyle = ( element.attributes.withFill ? fillColor : null)
    ctx.lineWidth = element.attributes.lineWidth;
    ctx.beginPath();
    let p0 = Element.rotatePointPage( element.points[0], rotation, canvas );
    let p1 = Element.rotatePointPage( element.points[1], rotation, canvas );
    ctx.moveTo(p0[0], p0[1]);
    ctx.lineTo(p0[0], p1[1]);
    ctx.lineTo(p1[0], p1[1]);
    ctx.lineTo(p1[0], p0[1]);
    ctx.lineTo(p0[0], p0[1]);
    ctx.stroke();
    if( element.attributes.withFill ) {
      ctx.fillRect( Math.min(p0[0],p1[0]),
      Math.min(p0[1],p1[1]),
      Math.abs(p1[0]-p0[0]),
      Math.abs(p1[1]-p0[1])
      )
    }

    if( element.attributes.color === '#FFFFFF') {
      ctx.setLineDash([2,2]);
      ctx.strokeStyle = "black";
      ctx.beginPath();
      ctx.moveTo(p0[0], p0[1]);
      ctx.lineTo(p0[0], p1[1]);
      ctx.lineTo(p1[0], p1[1]);
      ctx.lineTo(p1[0], p0[1]);
      ctx.lineTo(p0[0], p0[1]);
      ctx.stroke();
      ctx.setLineDash([])
    }
    
  }

  drawPdf(pdfPage, attr) {
    let element = this;
    if (!Array.isArray(element.points) || element.points.length < 2) return;
    let height = pdfPage.getHeight();
    let p1 = Element.pointToObject(element.points[0], height);
    let p2 = Element.pointToObject(element.points[1], height);
    let color = Element.hexToRGB(element.attributes.color.substring(1));
    let thickness = element.attributes.lineWidth;
    let opacity = element.attributes.opacity;
    let rectAttr = {
      x: Math.min(p1.x, p2.x),
      y: Math.min(p1.y, p2.y),
      width: Math.abs(p2.x-p1.x),
      height: Math.abs(p2.y-p1.y),
      borderWidth: thickness,
      borderColor: color,
      borderOpacity: opacity
    };
    if( element.attributes.withFill ) {
      rectAttr.color = Element.hexToRGB(element.attributes.fillColor.substring(1));
      rectAttr.opacity = opacity;
    }
    pdfPage.drawRectangle(rectAttr);
  }

  distanceSquared(pos, canvas) {
    let element = this;
    if (!Array.isArray(element.points) || element.points.length < 2) return Infinity;
    if( element.attributes.withFill ) {
      if( Element.inRect(element.points[0], element.points[1], pos)) {
        return 0;
      }
    }
    let a = element.points[0];
    let b = [element.points[0][0], element.points[1][1]];
    let c = element.points[1];
    let d = [element.points[1][0], element.points[0][1]];
    let ds = Element.getSquaredDistanceToSegment(a, b, pos);
    let dp = Element.getSquaredDistanceToSegment(b, c, pos);
    if (dp < ds) ds = dp;
    dp = Element.getSquaredDistanceToSegment(c, d, pos);
    if (dp < ds) ds = dp;
    dp = Element.getSquaredDistanceToSegment(d, a, pos);
    if (dp < ds) ds = dp;
    return ds;
  }
}


class Note extends Element {
  constructor(attributes) {
    super(attributes);
    this._type = 'note';
  }

  drawCanvas(canvas, {pageIndex, totalPages}, rotation) {
    let ctx = canvas.getContext("2d");
    let element = this;
    if (!Array.isArray(element.points) || element.points.length < 2) return;
    ctx.lineJoin = "round";
    ctx.lineCap = "round";
    let color = Element.hexToRGBAString(element.attributes.color.substring(1), element.attributes.opacity);
    ctx.strokeStyle = color;
    let fillColor = Element.hexToRGBAString(element.attributes.fillColor.substring(1), element.attributes.opacity);
    ctx.fillStyle = ( element.attributes.withFill ? fillColor : null)
    ctx.lineWidth = element.attributes.lineWidth;
    ctx.beginPath();
    ctx.moveTo(element.points[0][0], element.points[0][1]);
    ctx.lineTo(element.points[0][0], element.points[1][1]);
    ctx.lineTo(element.points[1][0], element.points[1][1]);
    ctx.lineTo(element.points[1][0], element.points[0][1]);
    ctx.lineTo(element.points[0][0], element.points[0][1]);
    ctx.stroke();
    if( element.attributes.withFill ) {
      ctx.fillRect( Math.min(element.points[0][0],element.points[1][0]),
      Math.min(element.points[0][1],element.points[1][1]),
      Math.abs(element.points[1][0]-element.points[0][0]),
      Math.abs(element.points[1][1]-element.points[0][1])
      )
    }

    ctx.strokeStyle = color;
    ctx.fillStyle = color;
    let size = element.attributes.font === 'MusiSync' ? element.attributes.fontSize*2 : element.attributes.fontSize;
    ctx.font = `${size}px ${element.attributes.font}`;

    let text = (element.attributes.text || '').replaceAll('{P}', `${pageIndex+1}`)
    .replaceAll('{T}', `${totalPages}`)

    let [p0, p1] = Element.getRect( element.points[0], element.points[1] );
    
    drawText( ctx, text, 
      { 
        x:p0[0]+size/3.0, 
        y:p0[1]+size/3.0, 
        width:(p1[0]-p0[0])-2*size/3.0, 
        height:(p1[1]-p0[1])-2*size/3.0,
        lineHeight: size * element.attributes.lineHeight, 
        align: element.attributes.align,
        vAlign: element.attributes.vAlign, 
        font:element.attributes.font, 
        fontSize:size
      })

  }

  async preDrawPdf( pdfDoc, canvas, {pageIndex, totalPages} ) {
    let element = this;
    if (!Array.isArray(element.points) || element.points.length < 1) return;
    if(this._font) return;
    if( element.attributes.font == 'MusiSync' ) {
      this._font = pdfDoc._musiSyncFont
    }
    else {
      this._font = await pdfDoc.embedFont(element.attributes.font);
    }

    let ctx = canvas.getContext("2d");
    let size = element.attributes.font == 'MusiSync' ? element.attributes.fontSize*2 : element.attributes.fontSize;
    ctx.font = `${size}px ${element.attributes.font}`;
    let text = (element.attributes.text || '');

    let lines = splitText( {ctx, text, justify:false, width: canvas.width} );
    let width = 0;
    let widths = [];
    for(let line of lines) {
      let w = ctx.measureText(line);
      widths.push(w.width);
      if( w.width > width) {
        width = w.width;
      }
    }
    let height = getTextHeight( { ctx, text, style:'normal' } );
    this._width = width;
    this._height = (lines.length - 1)*size*element.attributes.lineHeight+size;
    this._widths = widths;
    this._lines = lines;
  }

  postDrawPdf() {
    this._font = null;
    delete this._width;
    delete this._widths;
    delete this._lines;
    delete this._height;
  }

  drawPdf(pdfPage, {pageIndex, totalPages}) {
    let element = this;
    if (!Array.isArray(element.points) || element.points.length < 2) return;
    let height = pdfPage.getHeight();
    let p1 = Element.pointToObject(element.points[0], height);
    let p2 = Element.pointToObject(element.points[1], height);
    let color = Element.hexToRGB(element.attributes.color.substring(1));
    let thickness = element.attributes.lineWidth;
    let opacity = element.attributes.opacity;
    let rectAttr = {
      x: Math.min(p1.x, p2.x),
      y: Math.min(p1.y, p2.y),
      width: Math.abs(p2.x-p1.x),
      height: Math.abs(p2.y-p1.y),
      borderWidth: thickness,
      borderColor: color,
      borderOpacity: opacity
    };
    if( element.attributes.withFill ) {
      rectAttr.color = Element.hexToRGB(element.attributes.fillColor.substring(1));
      rectAttr.opacity = opacity;
    }
    pdfPage.drawRectangle(rectAttr);

    let size = element.attributes.font == 'MusiSync' ? element.attributes.fontSize*2 : element.attributes.fontSize;
    
    let [ a, b ] = Element.getRect( element.points[0], element.points[1]);
    let c = [ (a[0]+b[0])/2, (a[1]+b[1])/2];
    let pa = Element.pointToObject(a, height);
    let pb = Element.pointToObject(b, height);
    let pc = Element.pointToObject(c, height);
    let rectWidth = b[0]-a[0];
    let x = pa.x;
    let y = pc.y + size;
    let align =  element.attributes.align;
    let vAlign =  element.attributes.vAlign;

    rectAttr = {
      x: pa.x,
      y: pb.y,
      width: pb.x-pa.x,
      height: pa.y-pb.y,
      borderWidth: thickness,
      borderColor: Element.hexToRGB('FF0000'),
      borderOpacity: opacity
    };
    
    pdfPage.drawRectangle(rectAttr);

    if( vAlign == 'top') {
      y = pa.y - size - size/3.0;
    }
    else if( vAlign == 'bottom') {
      y = pb.y + this._height - size/3.0;
    }

    for(let i=0;i<this._lines.length;i++) {
      let line = this._lines[i];
      let txt = line.replaceAll('{P}', `${pageIndex+1}`)
      .replaceAll('{T}', `${totalPages}`)
      let sz = this._widths[i];
      let lx = x;
      if( align == 'right') {
        lx -= sz - rectWidth + size/3.0;;
      }
      else if( align == 'center') {
        lx -= sz/2 - rectWidth/2.0;
      }
      else {
        lx += size/3.0;
      }
      pdfPage.drawText(txt, {
        x: lx,
        y: y,
        size: size,
        font: this._font,
        color: color,
        opacity: element.attributes.opacity
      })
      y -= size*element.attributes.lineHeight;
    }
  }

  distanceSquared(pos, canvas) {
    let element = this;
    if (!Array.isArray(element.points) || element.points.length < 2) return Infinity;
    
    if( Element.inRect(element.points[0], element.points[1], pos)) {
        return 0;
    }
    let a = element.points[0];
    let b = [element.points[0][0], element.points[1][1]];
    let c = element.points[1];
    let d = [element.points[1][0], element.points[0][1]];
    let ds = Element.getSquaredDistanceToSegment(a, b, pos);
    let dp = Element.getSquaredDistanceToSegment(b, c, pos);
    if (dp < ds) ds = dp;
    dp = Element.getSquaredDistanceToSegment(c, d, pos);
    if (dp < ds) ds = dp;
    dp = Element.getSquaredDistanceToSegment(d, a, pos);
    if (dp < ds) ds = dp;
    return ds;
  }
}

class Mark extends Element {
  constructor(attributes) {
    super(attributes);
    this._type = 'mark';
  }

  drawCanvas(canvas, attr) {
    let ctx = canvas.getContext("2d");
    let element = this;
    if (!Array.isArray(element.points) || element.points.length < 2) return;
    ctx.lineJoin = "round";
    ctx.lineCap = "round";
    let color = Element.hexToRGBAString(element.attributes.color.substring(1), element.attributes.opacity);
    ctx.strokeStyle = color;
    ctx.fillStyle =  null;
    ctx.lineWidth = element.attributes.lineWidth;
    ctx.beginPath();
    ctx.moveTo(element.points[0][0], element.points[0][1]);
    ctx.lineTo(element.points[0][0], element.points[1][1]);
    ctx.lineTo(element.points[1][0], element.points[1][1]);
    ctx.lineTo(element.points[1][0], element.points[0][1]);
    ctx.stroke();
    
  }

  drawPdf(pdfPage, attr) {
    let element = this;
    if (!Array.isArray(element.points) || element.points.length < 2) return;
    let height = pdfPage.getHeight();
    let p1 = Element.pointToObject(element.points[0], height);
    let p2 = Element.pointToObject(element.points[1], height);
    let color = Element.hexToRGB(element.attributes.color.substring(1));
    let thickness = element.attributes.lineWidth;
    let opacity = element.attributes.opacity;

    pdfPage.drawLine({
      start: p1,
      end: {x:p1.x, y:p2.y},
      thickness,
      color,
      opacity,
    })

    pdfPage.drawLine({
      start: {x:p1.x, y:p2.y},
      end: p2,
      thickness,
      color,
      opacity,
    })

    pdfPage.drawLine({
      start: p2,
      end: {x:p2.x, y:p1.y},
      thickness,
      color,
      opacity,
    })
  }

  distanceSquared(pos, canvas) {
    let element = this;
    if (!Array.isArray(element.points) || element.points.length < 2) return Infinity;
    
    let a = element.points[0];
    let b = [element.points[0][0], element.points[1][1]];
    let c = element.points[1];
    let d = [element.points[1][0], element.points[0][1]];
    let ds = Element.getSquaredDistanceToSegment(a, b, pos);
    let dp = Element.getSquaredDistanceToSegment(b, c, pos);
    if (dp < ds) ds = dp;
    dp = Element.getSquaredDistanceToSegment(c, d, pos);
    if (dp < ds) ds = dp;
    return ds;
  }
}

class Imagen extends Element {
  constructor(attributes) {
    super(attributes);
    this._type = 'imagen';
  }

  drawCanvas(canvas, attr, rotation) {
    let ctx = canvas.getContext("2d");
    let element = this;
    if (!Array.isArray(element.points) || element.points.length < 2
    || element.attributes.width==0) return;

    let p1 = Element.rotatePointPage(element.points[0], rotation, canvas );
    let ang = (360 + rotation-element.attributes.rotation) % 360;
    
    ctx.globalAlpha = element.attributes.opacity;

    ctx.translate(p1[0],p1[1])
    ctx.rotate(Math.PI*ang/180);
    ctx.drawImage(element.attributes.image, 
      0, 
      0, 
      element.attributes.width * element.attributes.scale,
      element.attributes.height * element.attributes.scale);
    ctx.globalAlpha = 1;
    // Reset current transformation matrix to the identity matrix
    ctx.setTransform(1, 0, 0, 1, 0, 0)
  }

  async preDrawPdf( pdfDoc, canvas ) {
    let element = this;
    if (!Array.isArray(element.points) || element.points.length < 1 || this._pdfImage) return;
    let image = null;
    if( element.attributes.mime == 'image/png') {
      image = await pdfDoc.embedPng(element.attributes.data)
    }
    else if( element.attributes.mime == 'image/jpeg') {
      image = await pdfDoc.embedJpg(element.attributes.data)
    }
    this._pdfImage = image;
  }

  moveKeyPoint( n, p ) {
    let element = this;
    if( n===0 ) {
      let scale = element.attributes.scale;
      let pSize = Element.rotateLocalPoint([element.attributes.width*scale, element.attributes.height*scale], element.attributes.rotation);
      this._points[0] = p;
      this._points[1] = [ p[0]+pSize[0], p[1]+pSize[1] ];
    }
    else {
      let [pa, pb] = Element.getRect(this._points[0], p);
      let pc = Element.rotateLocalPoint([pb[0]-pa[0], pb[1]-pa[1]], element.attributes.rotation);
      let pd = Element.rotateLocalPoint([element.attributes.width, element.attributes.height], element.attributes.rotation);
      let scale = Math.abs(pc[0])/element.attributes.width;
      element.setAttribute('scale', scale);
      this._points[0] = pa;
      this._points[1] = [pa[0]+scale*pd[0], pa[1]+scale*pd[1]];
    }
  }

  setAttributes( attrs ) {
    let element = this;

    for(let name in attrs ) {
      element._attr[name] = attrs[name]
    }

    let scale = element.attributes.scale;
    let pSize = Element.rotateLocalPoint([element.attributes.width*scale, element.attributes.height*scale], element.attributes.rotation);
    //this._points[0] = p;
    this._points[1] = [ this._points[0][0]+pSize[0], this._points[0][1]+pSize[1] ];
  }

  postDrawPdf() {
    let element = this;
    if (!Array.isArray(element.points) || element.points.length < 1) return;
    this._pdfImage = null;
  }

  drawPdf(pdfPage, attr) {
    let element = this;
    if (!Array.isArray(element.points) || element.points.length < 2
    || element.attributes.width == 0
    || this._pdfImage==null) return;

    const { width, height } = pdfPage.getSize();
    
    let p1 = Element.pointToObject(element.points[0], height);
    let p2 = Element.rotateLocalPoint(
      [0, element.attributes.height*element.attributes.scale], 
      element.attributes.rotation);

    pdfPage.drawImage(this._pdfImage, {
      x: p1.x-p2[0],
      y: p1.y-p2[1],
      width: element.attributes.width * element.attributes.scale,
      height: element.attributes.height * element.attributes.scale,
      opacity: element.attributes.opacity,
      rotate: degrees(element.attributes.rotation)
    });
  }

  distanceSquared(pos, canvas) {
    let element = this;
    if (!Array.isArray(element.points) || element.points.length < 2) return Infinity;

    if( Element.inRect(
      element.points[0],
      element.points[1],
      pos,
      element.attributes.rotation
    )) {
      return 0;
    }
    return Element.getSquaredDistance( pos, element.points[0])
  }
}

class Text extends Element {
  constructor(attributes) {
    super(attributes);
    this._type = 'text';
  }

  drawCanvas(canvas, {pageIndex, totalPages}, rotation) {
    let ctx = canvas.getContext("2d");
    let element = this;
    if (!Array.isArray(element.points) || element.points.length < 1) return;

    let color = Element.hexToRGBAString(element.attributes.color.substring(1), element.attributes.opacity);
    ctx.strokeStyle = color;
    ctx.fillStyle = color;
    let size = element.attributes.fontSize;
    ctx.font = `${size}px ${element.attributes.font}`;

    //console.log('rotate',rotate, rotation)
    let text = (element.attributes.text || '').replaceAll('{P}', `${pageIndex+1}`)
    .replaceAll('{T}', `${totalPages}`)

    let p = Element.rotatePointPage(element.points[0], rotation, canvas );

    let lines = splitText( {ctx, text, justify:false, width: canvas.width} );
    let width = 0;
    for(let line of lines) {
      let w = ctx.measureText(line);
      if( w.width > width) {
        width = w.width;
      }
    }
    let height = (lines.length-1)*size*element.attributes.lineHeight + size; 

    //let [rw, rh] = Element.desrotatePoint( [width,height], rotation, canvas);
    
    let x = 0;
    let y = 0;
    let align = element.attributes.align;
    if( align == 'right' ) {
      x -= width;
    }
    else if(align == 'center' ) {
      x -= width/2;
    }

    let vAlign = element.attributes.vAlign;
    if( vAlign == 'bottom' ) {
      y -= height;
    }
    else if(vAlign == 'middle' ) {
      y -= height/2;
    }

    let ang = (360 + rotation-element.attributes.rotation) % 360;
    ctx.translate(p[0],p[1])
    ctx.rotate(Math.PI*ang/180);
    drawText( ctx, text, { 
      x, y, width, height,
      lineHeight: size*element.attributes.lineHeight, 
      align: align,
      vAlign: vAlign, 
      font:element.attributes.font, 
      fontSize: size
    })
    // Reset current transformation matrix to the identity matrix
    ctx.setTransform(1, 0, 0, 1, 0, 0);

  }

  distanceSquared(pos, canvas) {
    let element = this;
    if (!Array.isArray(element.points) || element.points.length < 1) return Infinity;
    let ctx = canvas.getContext("2d");
    let size = element.attributes.font == 'MusiSync' ? element.attributes.fontSize*2 : element.attributes.fontSize;

    let text = (element.attributes.text || '');

    let lines = splitText( {ctx, text, justify:false, width: canvas.width} );
    let width = 0;
    for(let line of lines) {
      let w = ctx.measureText(line);
      if( w.width > width) {
        width = w.width;
      }
    }
    let height = (lines.length-1)*size*element.attributes.lineHeight + size; 
    let x = element.points[0][0];
    let y = element.points[0][1];
    let align = element.attributes.align;
    if( align == 'right' ) {
      x -= width;
    }
    else if(align == 'center' ) {
      x -= width/2;
    }

    let vAlign = element.attributes.vAlign;
    if( vAlign == 'bottom' ) {
      y -= height;
    }
    else if(vAlign == 'middle' ) {
      y -= height/2;
    }
    
    if( Element.inRect(
      [x, y],
      [x+width, y+height],
      pos
    )) {
      return 0;
    }
    let ds = Element.getSquaredDistance( [x + width/2.0, y + height/2.0], pos);
    return ds;
  }

  async preDrawPdf( pdfDoc, canvas, {pageIndex, totalPages} ) {
    let element = this;
    if (!Array.isArray(element.points) || element.points.length < 1) return;

    let ctx = canvas.getContext("2d");
    let size = element.attributes.font == 'MusiSync' ? element.attributes.fontSize*2 : element.attributes.fontSize;
    ctx.font = `${size}px ${element.attributes.font}`;
    let text = (element.attributes.text || '').replaceAll('{P}', `${pageIndex+1}`)
    .replaceAll('{T}', `${totalPages}`);

    let lines = splitText( {ctx, text, justify:false, width: canvas.width} );
    if(!Array.isArray(lines) || lines.length===0) {
      lines = [text]
    }
    let width = 0;
    let widths = [];
    for(let line of lines) {
      let w = ctx.measureText(line);
      widths.push(w.width);
      if( w.width > width) {
        width = w.width;
      }
    }
    this._height = (lines.length-1)*size*element.attributes.lineHeight + size;
    this._width = width;
    this._widths = widths;
    this._lines = lines;
    /*
    if(this._font) return;
    if( element.attributes.font === 'MusiSync' ) {
      this._font = pdfDoc._musiSyncFont
    }
    else {
      this._font = await pdfDoc.embedFont(element.attributes.font);
    }*/
  }

  postDrawPdf() {
    //this._font = null;
    //delete this._height;
    //delete this._width;
    //delete this._widths;
    //delete this._lines;
  }

  drawPdf(pdfPage, {pageIndex, totalPages, fonts}) {
    let element = this;
    //debugger;
    if (!Array.isArray(element.points) || element.points.length < 1) return;
    const { width, height } = pdfPage.getSize();
    let p1 = Element.pointToObject(element.points[0], height);
    let color = Element.hexToRGB(element.attributes.color.substring(1));

    let size = element.attributes.fontSize;
     
    let x = p1.x;
    let y = p1.y;
    let align =  element.attributes.align;
    let vAlign =  element.attributes.vAlign;

    let cw = 0;
    let ch = -size*0.8;
    if( vAlign == 'bottom') {
      ch += this._height;
    }
    else if( vAlign == 'middle') {
      ch += this._height/2.0;
    }
    let [ rw, rh ] = Element.rotateLocalPoint( 
      [cw, ch],  // this._width 
      element.attributes.rotation)

    x += rw;
    y += rh;

    // pto inicial es x y

    let ly = 0;
    for(let i=0;i<this._lines.length;i++) {
      let line = this._lines[i];
      let txt = line.replaceAll('{P}', `${pageIndex+1}`).replaceAll('{T}', `${totalPages}`)
      let sz = this._widths[i];
      let lx = 0;
      if( align == 'right') {
        lx -= sz;
      }
      else if( align == 'center') {
        lx -= sz/2;
      }

      let [ lw, lh ] = Element.rotateLocalPoint( 
        [lx, ly], 
        element.attributes.rotation)

      pdfPage.drawText(txt, {
        x: x + lw,
        y: y + lh,
        size: size,
        font: fonts[element.attributes.font],
        color: color,
        opacity: element.attributes.opacity,
        rotate: degrees(element.attributes.rotation)
      })
      ly -= size*element.attributes.lineHeight;
    }
    
  }

}

class ElementFactory {
  static createElement( type, attributes ) {
    switch(type) {
      case 'line':
        return new Line(attributes);
      case 'circle':
        return new Circle(attributes);
      case 'rect':
        return new Rectangle(attributes);
      case 'note':
        return new Note(attributes);
      case 'mark':
        return new Mark(attributes);
      case 'keypoints':
        return new KeyPoints(attributes);
      case 'text':
        return new Text(attributes);
      case 'imagen':
        return new Imagen(attributes);
      default:
        throw new Error({message:`El tipo ${type} no existe`})
    }
  }

  static cloneElement( element, page, id ) {
    let type = element.type;
    let points = element.points.map( point => {
      return [ point[0], point[1] ]
    })
    let el = ElementFactory.createElement(type, {
      ...element.attributes,
      page,
      points
    })
    el.id = id || element.id;
    return el;
  }

  static drawElements( elements, { canvas, page, rotation, clearPage, onlyPage, ...other} ) {
    
    if( clearPage ) {
      let ctx = canvas.getContext("2d");
      ctx.clearRect(
        0,
        0,
        canvas.width,
        canvas.height
      );
    }
    
    let els = (elements || []).filter( e => e.page === page || (!onlyPage && e.attributes.allPages) );
    for (let element of els) {
      element.drawCanvas(canvas, {...other, page}, rotation)
    }
  }

  static async drawPdfElements( pdfPage, elements, { canvas, fonts, pageNumber, pageIndex, totalPages, rotation, ...other} ) {
    let els = elements.filter( e => e.page === pageNumber || e.attributes.allPages );
    
    for (let element of els) {
      await element.preDrawPdf(pdfPage.doc, canvas, { pageIndex, totalPages});
      element.drawPdf(pdfPage, { pageIndex, totalPages, fonts});
      element.postDrawPdf();
    }
    
  }
}

export { ElementFactory, Element, Line, Rectangle, Note, Circle, KeyPoints, Text, Imagen, Mark };
