import shajs from 'sha.js';

const Utils = {

    buildParameters: (options) => {
        if(!options) {
            return '';
        }
        let strs = [];
        for (let param in options) {
            let value = (typeof options[param] == 'object' ? JSON.stringify(options[param]) : options[param]);
            strs.push(`${param}=${encodeURIComponent(value)}`);
        }
        return strs.join('&');
    },

    cleanData: (data) => {
        for (let i in data) {
            if (data[i] === null || data[i] === undefined) {
                delete data[i];
            }
        }
    },

    // Limpia filtro de vacíos
    cleanFilterMongo: (filter) => {
        if (!filter || !Object.keys(filter).length) {
            return {}
        }
        let opts = [];
        for (let key in filter) {
            if (Array.isArray(filter[key])) {
                if (!filter[key].length) {
                    continue
                }
                else {
                    opts.push(Utils.filterFieldMongo(key, filter[key]))
                }
            }
            else if (!filter[key] && typeof filter[key] != "boolean") {
                continue;
            }
        }
        if (!opts.length) {
            return {}
        }
        return {
            '$and': opts
        }
    },

    clone: (object) => {
        return Object.assign({}, object);
    },

    compareJSON: (obj1, obj2, trim) => {
        if (typeof (obj1) !== typeof (obj2)) return false;
        if (obj1 === undefined || obj1 === null) {
            if (obj2 === undefined || obj2 === null) {
                return true;
            }
            return false;
        }

        if (obj2 === undefined || obj2 === null) {
            return false;
        }

        //Loop through properties in object 1
        if (Array.isArray(obj1)) {
            if (!Array.isArray(obj2)) {
                return false;
            }
            if (obj1.length !== obj2.length) {
                return false;
            }

            for (let i = 0; i < obj1.length; i++) {
                if (typeof (obj1[i]) != typeof (obj2[i])) return false;
                switch (typeof (obj1[i])) {
                    //Deep compare objects
                    case 'object':
                        if (!Utils.compareJSON(obj1[i], obj2[i], trim)) {
                            return false;
                        }
                        break;
                    //Compare values
                    default:
                        if (obj1[i] !== obj2[i]) {
                            return false;
                        }
                }
            }
            return true;
        }

        for (let p in obj1) {
            // si solo comunes
            if (trim) {
                if (!obj2.hasOwnProperty(p) || obj1[p] === null)
                    continue;
            }

            if (obj1[p] === null) {
                if (!obj2.hasOwnProperty(p) || obj2[p] === null || obj2[p] === undefined) {
                    continue;
                }
                return false;
            }

            //Check property exists on both objects
            if (!trim && (!obj2.hasOwnProperty(p) || obj2[p] === null)) {
                return false;
            }

            switch (typeof (obj1[p])) {
                //Deep compare objects
                case 'object':
                    if (!Utils.compareJSON(obj1[p], obj2[p], trim)) {
                        return false;
                    }
                    break;
                //Compare values
                default:
                    if (obj1[p] !== obj2[p]) {
                        return false;
                    }
            }
        }

        //Check object 2 for any extra properties
        if (!trim) {
            for (let p in obj2) {
                if (obj2[p] === null || obj2[p] === undefined) {
                    continue;
                }
                if (obj1[p] === undefined || obj1[p] === null) {
                    return false;
                }
            }
        }
        return true;
    },

    deepClone: (object) => {
        if (object) return JSON.parse(JSON.stringify(object))
        return object
    },


    // Construye un filtro para buscar texto en un campo
    filterFieldMongo: (selector, tokens) => {
        if (tokens.length == 1) {
            return {
                [selector]: tokens[0]
            }
        }
        let opts = [];
        for (let token of tokens) {
            opts.push({
                [selector]: token
            })
        }
        return { '$or': opts }
    },

    getDiffs: (original, cambiado) => {
        let diff = {};
        // Ver eliminados
        // Para todas las propiedades del original
        for (let property in original) {
            if (!cambiado.hasOwnProperty(property) && original[property] !== null) {
                // Se ha eliminado la propiedad en el cambiado
                diff[property] = null;
            }
        }

        // Para todas las propiedades del cambiado
        for (let property in cambiado) {
            // Añadidos
            if (!original.hasOwnProperty(property) && cambiado[property] !== null) {
                diff[property] = cambiado[property];
            } else {
                if (typeof (original[property]) == "object") {
                    if (!Utils.compareJSON(original[property], cambiado[property])) {
                        // Cambiados
                        diff[property] = cambiado[property];
                    }
                } else {
                    if (original[property] != cambiado[property]) {
                        diff[property] = cambiado[property];
                    }
                }
            }
        }

        return diff;
    },

    getField: (field, row) => {
        switch( typeof field ) {
            default:
            case "string":
                return row[field];
            case "function":
                return field( row );
            case "object":
                let obj = {}; 
                for(let key in row) {
                    obj[key] = Utils.getField( row[key], row )
                }
                return obj;
        }            
    },

    getSHA256: ( text ) => {
        return shajs("sha256").update(text).digest("hex");
    },

    makeSearchOptionMongo: (options) => {
        let { filter, search, columns } = options;
        let flt = Utils.cleanFilterMongo(filter);
        if (!search) {
            return flt;
        }
        // texto a buscar
        let tokens = search.split(' ');
        let fields = columns.filter(c => c.options && c.options.searchable);
        if (!fields.length) {
            return flt;
        }
        // Un solo campo
        if (fields.length == 1) {
            if (Object.keys(flt).length) {
                return {
                    "$and": [
                        flt,
                        Utils.searchFieldMongo(fields[0].name, tokens)
                    ]
                }
            }
            return Utils.searchFieldMongo(fields[0].name, tokens)
        }

        let opts = [];
        for (let field of fields) {
            opts.push(Utils.searchFieldMongo(field.name, tokens))
        }

        if (Object.keys(flt).length) {
            return {
                "$and": [
                    flt,
                    {
                        "$or": opts
                    }
                ]
            }
        }
        return {
            "$or": opts
        }
    },

    // Construye un filtro para buscar texto en un campo
    searchFieldMongo: (selector, tokens) => {
        if( tokens.length == 0 ) {
            return {};
        }
        if (tokens.length == 1) {
            return {
                [selector]: { "$regex": `.*${tokens[0]}.*`, "$options": 'i' }
            }
        }
        let opts = [];
        for (let token of tokens) {
            opts.push({
                [selector]: { "$regex": `.*${token}.*`, "$options": 'i' }
            })
        }
        return { '$and': opts }
    },

    UUID: (a) => {
        return a
            ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
            : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, Utils.UUID)
    },
}

Object.freeze(Utils)

export default Utils
