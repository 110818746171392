import React, { useState, useEffect, useMemo } from "react";
import {
  Button, ColorPicker, Dropdown, Form, Input, Modal, Popconfirm,
  Radio, Select, Slider, Switch, Upload
} from "antd";
import { UUID } from "../hooks/Elements";
import {
  AlignLeftOutlined, AlignCenterOutlined, AlignRightOutlined,
  BorderOutlined,
  VerticalAlignTopOutlined, VerticalAlignMiddleOutlined, VerticalAlignBottomOutlined,
  XFilled
} from "@ant-design/icons";
import { StandardFonts } from 'pdf-lib';
import Colors from './canvasColors.json';

const optionsFonts = [
  { label: 'Courier', value: StandardFonts.Courier },
  { label: 'Courier-Bold', value: StandardFonts.CourierBold },
  { label: 'Courier-Oblique', value: StandardFonts.CourierOblique },
  { label: 'Courier-BoldOblique', value: StandardFonts.CourierBoldOblique },
  { label: 'Helvetica', value: StandardFonts.Helvetica },
  { label: 'Helvetica-Bold', value: StandardFonts.HelveticaBold },
  { label: 'Helvetica-Oblique', value: StandardFonts.HelveticaOblique },
  { label: 'Helvetica-BoldOblique', value: StandardFonts.HelveticaBoldOblique },
  { label: 'Times-Roman', value: StandardFonts.TimesRoman },
  { label: 'Times-Bold', value: StandardFonts.TimesRomanBold },
  { label: 'Times-Italic', value: StandardFonts.TimesRomanItalic },
  { label: 'Times-BoldItalic', value: StandardFonts.TimesRomanBoldItalic },
  { label: 'Symbol', value: StandardFonts.Symbol },
  { label: 'ZapfDingbats', value: StandardFonts.ZapfDingbats },
  //{ label: 'MusiSync', value: 'MusiSync' }
]

const optionsFontSize = [
  { label: '30pt', value: 30 },
  { label: '24pt', value: 24 },
  { label: '20pt', value: 20 },
  { label: '16pt', value: 16 },
  { label: '14pt', value: 14 },
  { label: '12pt', value: 12 },
  { label: '10pt', value: 10 },
  { label: '9pt', value: 9 },
  { label: '8pt', value: 8 }
]

const optionsLineWidth = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
]

const optionsRotation = [
  { label: '0', value: 0 },
  { label: '90', value: 90 },
  { label: '180', value: 180 },
  { label: '270', value: 270 },
]

const attributeList = [
  { name: 'font', label: 'Fuente', value: StandardFonts.Helvetica, type: 'options', options: optionsFonts },
  { name: 'fontSize', label: 'Tamaño', value: 10, type: 'options', options: optionsFontSize },
  { name: 'lineWidth', label: 'Grosor línea', value: 1, type: 'options', options: optionsLineWidth },
  { name: 'text', label: 'Texto', value: '{P} de {T}', type: 'string', multiline: true },
  { name: 'rotation', label: 'Rotación', value: 0, type: 'options', options: optionsRotation },
  { name: 'rotation', label: 'Rotación', value: 0, type: 'slider', min: 0, max: 355, step: 5 },
  { name: 'color', label: 'Color', value: '#000000', type: 'color' },
  { name: 'withFill', label: 'Con relleno', value: false, type: 'boolean' },
  { name: 'fillColor', label: 'Color relleno', value: '#000000', type: 'color' },
  { name: 'opacity', label: 'Opacidad', value: 1.0, type: 'slider', min: 0.0, max: 1.0, step: 0.1 },
  { name: 'allPages', label: 'Todas las páginas', value: false, type: 'boolean' },
  { name: 'lineHeight', label: 'Altura de línea', value: 1.5, type: 'slider', min: 0.5, max: 2.0, step: 0.1 },
  { name: 'align', label: 'Alineamiento', value: 'center', type: 'align' },
  { name: 'vAlign', label: 'Alineamiento vertical', value: 'middle', type: 'vAlign' },
];

const defaultAttributes = attributeList.reduce((prev, current) => {
  prev[current.name] = current.value
  return prev;
}, {});


const attributeTypes = {
  'options': (attr) => {
    let { name, value, label, options, onChange, ...other } = attr;
    return <Form.Item style={{ marginRight: 10, marginBottom: 10, float: 'right' }} layout="horizontal" label={label}>
      <Select
        style={{
          width: '7vw'
        }}
        {...other}
        options={options}
        value={value}
        onChange={(v) => { onChange({ name, value: v }) }}
      />
    </Form.Item>
  },
  'string': (attr) => {
    let { name, value, label, multiline, onChange, ...other } = attr;
    return <Form.Item className="stretchForm" style={{ marginRight: 10, marginLeft: 10, marginBottom: 10 }} layout="vertical" label={label}>
      {
        multiline &&
        <Input.TextArea
          {...other}
          value={value}
          onChange={(v) => {
            onChange({ name, value: v.target.value })
          }}
        />
      }
      {
        !multiline &&
        <Input
          {...other}
          value={value}
          onChange={(v) => { onChange({ name, value: v.target.value }) }}
        />
      }

    </Form.Item>
  },
  'color': (attr) => {
    let { disabled, name, value, label, onChange, ...other } = attr;
    return <div style={{float:'right'}}>
      <Form.Item layout="horizontal" style={{marginRight:'1em'}} label={label}>
      <Dropdown.Button
        title='Color'
        disabled={disabled}
        menu={{
          onClick: (e) => {
            let color = Colors[e.key].background
            onChange({ name, value: color })
          },
          items: Object.keys(Colors).map(key => ({
            key: key,
            icon: Colors[key].background === '#FFFFFF' ?
              <BorderOutlined />
              : <XFilled style={{ color: Colors[key].background }} />
          }))
        }}
      ><ColorPicker
        value={value}
        disabled={disabled}
        onChange={(v) => { onChange({ name, value: v.toHexString() }) }}
      />
      </Dropdown.Button>
      </Form.Item>
    </div>
  },
  'boolean': (attr) => {
    let { name, value, label, onChange, ...other } = attr;
    return <div style={{ display: 'flex', flexDirection: 'row' }} >
      <div style={{ flexGrow: 0.5 }}></div>
      <Form.Item layout="horizontal" style={{ marginRight: 10, marginBottom: 10 }} label={label}>
        <Switch
          {...other}
          value={value}
          onChange={(v) => { onChange({ name, value: v }) }}
        />
      </Form.Item></div>
  },
  'align': (attr) => {
    let { name, value, label, onChange, ...other } = attr;
    return <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ flexGrow: 0.5 }}></div>
      <Radio.Group
        {...other}
        optionType="button"
        buttonStyle="solid"
        value={value}
        onChange={(v) => {
          onChange({ name, value: v.target.value })
        }}>
        <Radio.Button type="primary" value="left"><AlignLeftOutlined /></Radio.Button>
        <Radio.Button type="primary" value="center"><AlignCenterOutlined /></Radio.Button>
        <Radio.Button type="primary" value="right"><AlignRightOutlined /></Radio.Button>
      </Radio.Group>
    </div>
  },
  'vAlign': (attr) => {
    let { name, value, label, onChange, ...other } = attr;
    return <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ flexGrow: 0.5 }}></div>
      <Radio.Group
        {...other}
        optionType="button"
        buttonStyle="solid"
        value={value}
        onChange={(v) => { onChange({ name, value: v.target.value }) }}>
        <Radio.Button value="top"><VerticalAlignTopOutlined /></Radio.Button>
        <Radio.Button value="middle"><VerticalAlignMiddleOutlined /></Radio.Button>
        <Radio.Button value="bottom"><VerticalAlignBottomOutlined /></Radio.Button>
      </Radio.Group>
    </div>
  },
  'slider': (attr) => {
    let { name, value, label, min, max, step, onChange, ...other } = attr;
    return <Form.Item style={{ marginRight: 15, marginBottom: 10, float: 'right' }} layout="horizontal" label={label}>
      <Slider
        style={{
          width: '8vw'
        }}
        {...other}
        value={value}
        min={min}
        max={max}
        step={step}
        onChange={(v) => { onChange({ name, value: v }) }}
      />
    </Form.Item>
  }
}

function Attributes({ value, onChange }) {
  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'fit-content',
    alignItems: 'right',
    margin: 2,
  }}><div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ flexGrow: 0.5 }}></div>
      <div style={{
        fontSize: 20,
        fontWeight: 'bold'
      }}>Atributos</div>
    </div>
    {
      attributeList.map(attr =>
        <div key={UUID()}>
          {
            attributeTypes[attr.type]({
              ...attr,
              disabled: (value[attr.name] === undefined),
              value: value[attr.name],
              onChange
            })
          }
        </div>)
    }
  </div>
}

export { Attributes, attributeList, defaultAttributes, optionsFonts, optionsFontSize, optionsLineWidth, optionsRotation };