import * as React from "react";
const SVGComponent = (props) => (
   <svg
   width="26"
   height="26"
   viewBox="0 0 48 48"
   xmlns="http://www.w3.org/2000/svg"
   xmlnsXlink="http://www.w3.org/1999/xlink"
   {...props}>
  <g>
    <rect
       style={{fill:'none',stroke:'#fff',strokeWidth:2,strokeLinecap:'round',
       strokeLinejoin:'round',strokeDasharray:'none',strokeOpacity:1}}
       width="20.745989"
       height="35.7966"
       x="2.000005"
       y="6.0000052" />
    <rect
       style={{fill:'none',stroke:'#fff',strokeWidth:2,strokeLinecap:'round',
       strokeLinejoin:'round',strokeDasharray:'none',strokeOpacity:1}}
       width="20.745762"
       height="35.796612"
       x="26"
       y="6" />
    <text
       xmlSpace="preserve"
       style={{fontStyle:'normal',fontWeight:'normal',
       fontSize:'21px',lineHeight:1.25,fontFamily:'sans-serif',
       fill:'#fff',fillOpacity:1,stroke:'none'}}
       x="6.1041713"
       y="31.562475"
       id="text1"><tspan
         id="tspan1"
         x="6.1041713"
         y="31.562475">1</tspan></text>
    <text
       xmlSpace="preserve"
       style={{fontStyle:'normal',fontWeight:'normal',
       fontSize:'21px',lineHeight:1.25,fontFamily:'sans-serif',
       fill:'#fff',fillOpacity:1,stroke:'none'}}
       x="29.32292"
       y="31.833309"
       id="text1-5"><tspan
         id="tspan1-9"
         x="29.32292"
         y="31.833309">2</tspan></text>
  </g>
</svg>
);
export default SVGComponent;

