import React from 'react';
import useMongo from './useMongo';

const useSubbasins = (token) => {
  
  const { model, columns, components, api, axios, authHeader } = useMongo({
    database: 'chg',
    collection: 'subbasins',
    columns: [],
    components: []
  });

  return { model, columns, components, api }
}

export default useSubbasins;