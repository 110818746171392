import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
import { Document } from 'react-pdf';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import MiniaturePDF from "./MiniaturePDF";
import './pdf.css';

const FACTOR = 72 / 25.4;

const MiniPdfView = ({ dimOfPages, docAttributes, elements, onLoadSuccess, onPageLoadSuccess,
  onMovePage, onSelectPage, onRotatePage, orderOfPages, pageAttributes, pageIndex, pdfDoc, 
  selectedPages, onChangeSelectedPages, rotationOfPages, totalPages, widthContainer, ...other }) => {
  const [pdfBytes, setPdfBytes] = useState(false)
  const [localNumPages, setLocalNumPages] = useState(0);

  useEffect(() => {
    async function fetchBytes() {
      let _pdfbytes = await pdfDoc.save();
      setPdfBytes({ data: _pdfbytes });
    }

    if (pdfDoc)
      fetchBytes();
  }, [pdfDoc])

  function realWidth(rotation, dimPage) {
    if(rotation===90 || rotation===270) return dimPage.height;
    return dimPage.width;
  }

  const maxWidth = useMemo( () => {
    let m = 0;
    for(let i=0;i<totalPages;i++) {
      let rw = realWidth( rotationOfPages[i], dimOfPages[i] );
      if( rw > m ) m = rw;
    }
    return m;
  }, [dimOfPages, rotationOfPages, totalPages])

  function onDocumentLoadSuccess({ numPages }) {
    setLocalNumPages(numPages);
    if(onLoadSuccess) onLoadSuccess({numPages});
  }

  const onAddSelectedPage = (pageIndex) => {
    let prev = [ ...selectedPages]
    let i = prev.indexOf(pageIndex);
    if( i > -1 ) {
      prev.splice(i,1)
    }
    else {
      prev.push(pageIndex)
    }
    if( onChangeSelectedPages ) {
      onChangeSelectedPages(prev);
    }
  }

  return <div className="container_mini_document">
    {pdfBytes &&
      <DndProvider backend={HTML5Backend}><Document
        {...docAttributes}
        file={pdfBytes}
        onLoadSuccess={onDocumentLoadSuccess}>
          {[...Array(totalPages).keys()].map( index => {
            return orderOfPages[index] < localNumPages ? 
            <MiniaturePDF
              key={`miniature-${index}`}
              dimPage={dimOfPages[index]}
              onMovePage={onMovePage}
              onSelectPage={onSelectPage}
              onRotatePage={onRotatePage}
              elements={elements}
              onAddSelectedPage={onAddSelectedPage}
              isSelected={selectedPages.indexOf(index)>-1}
              isActual={index===pageIndex}
              pageNumber={orderOfPages[index]}
              pageIndex={index}
              totalPages={totalPages}
              rotation={rotationOfPages[index]}
              widthContainer={widthContainer}
              maxWidth={maxWidth}
            />
            :
              <div key={`miniature-${index}`}></div>
          })}
      </Document></DndProvider>
    }
  </div>
}

export default MiniPdfView;