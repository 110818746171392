import React, { useContext, useEffect, useState } from "react";
import { Affix, Card, Flex, Modal, Button } from "antd";
import { UserContext } from '../others/UserContext';
import { Main } from "../layouts";
import { Link } from "react-router-dom";
import useFtpBinaries from '../hooks/useFtpBinaries';
import Colors from '../colors.json';
import { RedoOutlined } from "@ant-design/icons";
import PacmanLoader from "react-spinners/PacmanLoader";

const ScanCard = ({ _id, name, ext, api, token }) => {
  return <img
    onClick={() => {
      window.open(`${api}_binaries/${_id}?download=true&database=ftp&token=${token}`, '_blank')
    }}
    style={{ cursor: 'pointer' }}
    title={`${name}${ext}`}
    src={`${api}_binaries/${_id}?thumb=true&database=ftp&token=${token}`}
    width={150}
    height={150}
    alt={name}></img>
}

const Scanner = () => {
  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState('Cargando...');
  const [reload, setReload] = useState(0);
  const [binaries, setBinaries] = useState([]);

  const context = useContext(UserContext);
  const { model: modelFtp, api } = useFtpBinaries(context.token);

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "#50C878",
  };

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      setLoadingText('Cargando datos...')
      let bins = await modelFtp.getList({
        filter: {
        },
        sort: {
          name: -1
        },
        token: context.token
      })
      console.log(bins)
      setBinaries(bins);
      setLoading(false);
    }
    fetchData();
  }, [context, reload])

  const refreshBinaries = async () => {
    try {
      setLoading(true);
      setLoadingText('Refrescando archivos...')
      let redo = await modelFtp.refreshData({ token: context.token });
      setReload(prev => prev + 1);
    }
    catch (err) {
      console.log('Error:', err.message)
    }
  }

  return (
    <Main
      cantView={!context?.roles?.ftp}
      header={
        <Button
          disabled={loading || context?.roles?.ftp !== 'write'}
          onClick={refreshBinaries}
          style={{
            fontSize: "16px",
            width: 64,
            height: 64,
            zIndex: 999,
          }}
          title='Refresca los archivos escaneados'
          type="primary" danger shape="circle" icon={<RedoOutlined />} />
      }>
      {
        loading && <Affix
          style={{ position: 'absolute', top: '40%', left: '50%' }}
        ><div><div>{loadingText}</div><PacmanLoader
          color={'#50C878'}
          loading={true}
          cssOverride={override}
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
        /></div>
        </Affix>
      }
      {
        !loading &&
        <div style={{ height: '100%', overflow: 'scroll' }}><Flex wrap gap="middle">
          {
            (binaries || []).map((binary, index) => {
              return <ScanCard
                key={`B-${index}`}
                {...binary}
                api={api}
                token={context.token}
              />
            })
          }
        </Flex>
        </div>
      }
    </Main>
  );
};

export default Scanner;
