import React from 'react'

export const UserData = {
  user_id: '',
  authed: false,
  user: '',
  database: '',
  name: '',
  databases:[],
  token: ''
};

export const UserContext = React.createContext(
  UserData
);
