import React, { useState, useEffect, useMemo } from "react";
import { Button, Form, Modal, Popconfirm, Select, Switch, Upload } from "antd";
import {
  BorderOutlined, CheckSquareOutlined, DeleteTwoTone, FileAddOutlined,
  DownloadOutlined, FolderOpenTwoTone, MenuOutlined, PlusCircleTwoTone,
  RotateRightOutlined, RotateLeftOutlined,
  FolderOpenOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import IconCombine from "../icons/IconCombine.js";
import PropTypes from 'prop-types';

const zoomOptions = [
  { label: '200%', value: 2 },
  { label: '100%', value: 1 },
  { label: '75%', value: 0.75 },
  { label: '50%', value: 0.5 },
  { label: '25%', value: 0.25 },
  { label: '10%', value: 0.1 }
]

function HeaderPDF({ addFile, addPage, combinePages, deletePages, downloadFile, downloadSelected, downloadElements, totalPages, totalSelected, zoom,
  onChangeZoom, openElementsFile, pageIndex, onChangePageIndex, openNewFile,
  rotateRight, rotateLeft, showLeftSider, setShowLeftSider, showRightSider, setShowRightSider,
  selectAll, selectNone, widthContainer, ...other }) {
  const [fileList, setFileList] = useState([]);
  const [showModalBefore, setShowModalBefore] = useState(false);
  const [modalFunction, setModalFunction] = useState(()=>{});
  const [beforePage, setBeforePage] = useState(-1);

  const pageOptions = useMemo(() => {
    return [...Array(totalPages).keys()].map(v => ({
      value: v,
      label: `${v + 1}`
    }));
  }, [totalPages]);

  const beforePageOptions = useMemo(() => {
    return [
      ...pageOptions,
      {
        value: -1,
        label: 'Al final'
      }
    ]
  }, [pageOptions])

  return <div
    style={{
      width: '100%',
      display: 'flex',
      flexDirection: 'row'
    }}
  ><Form
  className="stretchForm"
  layout="inline"><Form.Item className="text-center" layout="vertical" label={<b>Miniaturas</b>}>
    <Switch
      type="primary"
      value={showLeftSider}
      title={showLeftSider ? "Oculta miniaturas" : "Visualiza miniaturas"}
      onChange={setShowLeftSider}></Switch>
    </Form.Item>
    </Form>
    <div style={{
      flexGrow: 1
    }}>
    </div>
    <Form
      className="stretchForm"
      layout="inline">
      <Form.Item layout="vertical" label={<b>Combina</b>}>
        <Button
          type='primary'
          disabled={totalPages === 1}
          title="Combina páginas"
          onClick={() => {
            Modal.confirm({
              title: 'Combinar páginas',
              content: '¿Seguro que quiere combinar las páginas?',
              async onOk() {
                await combinePages()
              }
            })
          }}><IconCombine /></Button>
      </Form.Item>
      <Form.Item layout="vertical" label={<b>Zoom</b>}>
        <Select
          options={zoomOptions}
          value={zoom}
          onChange={onChangeZoom}
        />
      </Form.Item>
      {totalPages > 0 &&
        <Form.Item layout="vertical" label={<b>Página</b>}>
          <Select
            options={pageOptions}
            value={pageIndex}
            onChange={onChangePageIndex}
          />
        </Form.Item>
      }
      <Form.Item layout="vertical" label={<b>Selecciona</b>}>
        <Button.Group>
          <Button
            type='primary'
            title="Selecciona todas"
            disabled={totalPages === totalSelected}
            onClick={selectAll}><CheckSquareOutlined /></Button>
          <Button
            type='primary'
            title="Limpia la selección"
            onClick={selectNone}><BorderOutlined /></Button>
        </Button.Group>
      </Form.Item>
      <Form.Item layout="vertical" label={<b>Seleccionadas</b>}>
        <Button.Group>
          <Button type='primary' title="Rota a la derecha" disabled={!totalSelected} onClick={rotateRight}><RotateRightOutlined /></Button>
          <Button type='primary' title="Rota a la izquierda" disabled={!totalSelected} onClick={rotateLeft}><RotateLeftOutlined /></Button>
          <Button
            type='primary'
            title='Elimina páginas seleccionadas'
            disabled={!totalSelected || totalPages === totalSelected}
            onClick={() => {
              Modal.confirm({
                title: 'Elimina páginas seleccionadas',
                content: '¿Estás seguro?',
                async onOk() {
                  await deletePages()
                }
              })
            }}><DeleteOutlined /></Button>
          <Button type='primary' title="Descarga seleccionadas" disabled={!totalSelected} onClick={downloadSelected}><DownloadOutlined /></Button>
        </Button.Group>
      </Form.Item>
      <Form.Item layout="vertical" label={<b>Elementos</b>}>
        <Button.Group>
          <Upload
              accept="application/json"
              beforeUpload={async (e) => {
                await openElementsFile(e);
                setFileList([])
                return false;
              }}
              fileList={fileList}
            >
              <Button title='Abre archivo de elementos' type='primary'><FolderOpenOutlined /></Button>
          </Upload>
          <Button type='primary' title="Descarga elementos" onClick={downloadElements}><DownloadOutlined /></Button>
        </Button.Group>
      </Form.Item>
      <Form.Item layout="vertical" label={<b>Archivo</b>}>
        <Button.Group>
          <Upload
            accept="application/pdf"
            beforeUpload={async (e) => {
              await openNewFile(e);
              setFileList([])
              return false;
            }}
            fileList={fileList}
          >
            <Button title='Abre nuevo PDF' type='primary'><FolderOpenOutlined /></Button>
          </Upload>
          <Upload
            accept="application/pdf"
            beforeUpload={async (file) => {
              let f = async function(bp) {
                await addFile(file,bp)
              }
              setModalFunction(() => f);
              setShowModalBefore(true);
              setFileList([])
              return false;
            }}
            fileList={fileList}
          >
            <Button type='primary' title="Añade archivo PDF"><PlusCircleTwoTone /></Button>
          </Upload>
          <Button type='primary' title="Descarga PDF" onClick={downloadFile}><DownloadOutlined /></Button>
          <Button type='primary' title="Añade página en blanco" onClick={() => {
            let f = async function(bp) {
              await addPage(bp)
            }
            setModalFunction(() => f);
            setShowModalBefore(true);
          }}><FileAddOutlined /></Button>
        </Button.Group>
      </Form.Item>
      <Form.Item className="text-center" layout="vertical" label={<b>Atributos</b>}>
      <Switch
        type="primary"
        value={showRightSider}
        title={showRightSider ? "Oculta atributos" : "Visualiza atributos"}
        onChange={setShowRightSider}></Switch>
      </Form.Item>
    </Form>
    <Modal
      title="Inserta archivo"
      open={showModalBefore}
      onOk={async () => {
        await modalFunction(beforePage);
        setShowModalBefore(false);
      }}
      onCancel={() => { setShowModalBefore(false) }}>
      <Form layout="vertical">
        <Form.Item layout="vertical" label='Antes de la página:'>
          <Select
            options={beforePageOptions}
            value={beforePage}
            onChange={setBeforePage}
          />
        </Form.Item>
      </Form>
    </Modal>
  </div>
}

export { HeaderPDF as default, zoomOptions};