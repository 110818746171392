import React, { useCallback, useContext, useEffect, useState, useRef, useMemo } from "react";
import { Affix, Card, Flex, Modal, Button, Layout, Dropdown, Result } from "antd";
import { PDFDocument, StandardFonts, rgb, degrees, PageSizes, values } from 'pdf-lib';
import { UserContext } from '../others/UserContext';
import { useDeviceSelectors } from 'react-device-detect';
import { pdfjs } from 'react-pdf';
import Colors from '../colors.json';
import { MiniPdfView, MainEditorPdfView, HeaderPDF, Attributes, defaultAttributes, optionsFonts } from "../components";
import { saveAs } from "file-saver";
import { useSnackbar } from 'notistack';
import useResizeObserver from "use-resize-observer";
import { ElementFactory } from "../hooks/Elements.js";
import { Jimp } from 'jimp';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

const FACTOR = 72 / 25.4;

const headerStyle = {
  padding: 5, background: Colors.info.background,
  margin: 0,
  width: '100%',
  height: '70px',
};

const contentStyle = {
  overflow: 'scroll',
  //textAlign: 'center',
  //minHeight: 400,
  color: '#000',
  backgroundColor: Colors.secondary.background,
  height: 'calc(100vh - 70px - 20px)',
};

const siderLeftStyle = {
  overflow: 'scroll',
  color: '#000',
  backgroundColor: Colors.secondary.background,
  height: 'calc(100vh - 70px - 20px)',
  width: '15%',
  zIndex: 2
};

const siderRightStyle = {
  color: '#000',
  backgroundColor: Colors.info.background,
  height: 'calc(100vh - 70px - 20px)',
  overflow: 'scroll',
};

const footerStyle = {
  textAlign: 'center',
  height: '20px',
  backgroundColor: Colors.info.background,
  margin: 0,
  padding: 0
};

const layoutStyle = {
  height: '100%',
  zIndex: 2
};

const EditPdf = () => {
  const context = useContext(UserContext);
  const { Header, Footer, Sider, Content } = Layout;
  const [pdfDoc, setPdfDoc] = useState(null);
  const [fileName, setFileName] = useState('demo');
  const [dimOfPages, setDimOfPages] = useState([]);
  const [orderOfPages, setOrderOfPages] = useState([]);
  const [rotationOfPages, setRotationOfPages] = useState([]);
  const [selectedPages, setSelectedPages] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [showLeftSider, setShowLeftSider] = useState(true);
  const [showRightSider, setShowRightSider] = useState(true);
  const [attributes, setAttributes] = useState(defaultAttributes);
  const [elements, setElements] = useState(defaultAttributes);
  const [zoom, setZoom] = useState(0.5);
  const [fonts, setFonts] = useState({});

  const refLeftContainer = useRef(null);
  const refAuxCanvas = useRef(null);

  const { width: widthLeftContainer } = useResizeObserver({ ref: refLeftContainer });

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [selectors, agentData] = useDeviceSelectors(window.navigator.userAgent)
  const { isTablet } = selectors;

  useEffect(() => {
    //debugger;

    const fetchData = async () => {
      const _pdfDoc = await PDFDocument.create();
      const _page = _pdfDoc.addPage();
      const { width, height } = _page.getSize()
      //console.log(width, height)
      /*let els = [{ "type": "line", "attributes": { "lineWidth": 1, "color": "#3B71CA", "allPages": false, "opacity": 1, "page": 0, "points": [[5.641829606528817, 5.638475250830338], [6.347058307344919, 832.4590173447718], [588.8659651814453, 831.7542072662587], [586.750279078997, 4.9336651723172755], [6.347058307344919, 4.228855093804214], [6.347058307344919, 4.228855093804214], [6.347058307344919, 4.228855093804214], [133.2882244542433, 89.51087459388472], [133.2882244542433, 89.51087459388472]] } }]
      let nels = [];
      for (let el of els) {
        let element = ElementFactory.createElement(el.type, el.attributes);
        nels.push(element)
      }*/

      //debugger;
      let fnts = {};
      for (let f of optionsFonts) {
        let fnt = await _pdfDoc.embedFont(f.value);
        fnts[f.value] = fnt
      }


      setFonts(fnts);
      setElements([]);
      setDimOfPages([{ width, height }]);
      setTotalPages(1)
      setOrderOfPages([0]);
      setRotationOfPages([0])
      setPdfDoc(_pdfDoc);
    }

    fetchData();

    if (isTablet) {
      document.onTouchMove = (e) => {
        e.preventDefault();
      }
    }
  }, []);


  const getCombinedX = (n, width, height) => {
    if (n == 0 || n == 2) {
      return 0
    }
    if (height < width) {
      return 0
    }
    return height / 2;
  }

  const getCombinedY = (n, width, height) => {
    if (height < width) {
      if (n == 0) return width / 2;
      return 0;
    }
    return 0;
  }

  const combinePages = async () => {
    try {
      let pdfOne = pdfDoc;
      let newOrder = [];
      let newRot = [];
      let newDims = [];
      let npages = totalPages;
      //debugger;
      let pdfTwo = await PDFDocument.create();
      const { width, height } = dimOfPages[0];
      let embeddedPages = [];
      for (let i = 0; i < npages; i++) {
        let n = orderOfPages[i];
        let pageSub = pdfOne.getPage(n);
        pageSub.drawText(' ', { x: 0, y: 0 })
        let embedPage = await pdfTwo.embedPage(pageSub);
        embeddedPages.push(embedPage);
      }

      let factor = 1;
      if (height < width) {
        // apaisado
        let nh = width; // / 2;
        let f1 = height / width;
        let f2 = nh / height;
        factor = (f1 < f2 ? f1 : f2);
      }
      else {
        let nw = height; //  / 2;
        let f1 = width / height;
        let f2 = nw / width;
        factor = (f1 < f2 ? f1 : f2);
      }
      let pNumber = 0;
      for (let i = 0; i < npages; i += 2) {
        const page = pdfTwo.addPage([height, width]);
        newOrder.push(pNumber++)
        newRot.push(0);
        newDims.push({
          height: width, width: height
        })
        page.drawPage(embeddedPages[i], {
          x: getCombinedX(0, width, height),
          y: getCombinedY(0, width, height),
          xScale: factor,
          yScale: factor
        });
        page.drawPage(embeddedPages[i + 1], {
          x: getCombinedX(1, width, height),
          y: getCombinedY(1, width, height),
          xScale: factor,
          yScale: factor
        });
      }

      // Actualiza valores
      setTotalPages(pNumber);
      setPdfDoc(pdfTwo);
      setOrderOfPages(newOrder);
      setDimOfPages(newDims);
      setRotationOfPages(newRot);
    }
    catch (err) {
      enqueueSnackbar(`ERROR: ${err.message}`, { variant: 'error' })
    }
  }

  const openElementsFile = (file) => {
    try {
      let fr = new FileReader();

      fr.onload = async function (e) {
        let els = JSON.parse(e.target.result);
        let nels = [];
        for (let el of els) {
          if(el.type==='imagen') {
            let img = new Image();
            img.src = el.attributes.data;
            el.attributes.image = img;
          }
          let element = ElementFactory.createElement(el.type, el.attributes);
          nels.push(element);
        }
        setElements(nels);
      }

      fr.readAsText(file);      
    }
    catch (err) {
      enqueueSnackbar(`ERROR: ${err.message}`, { variant: 'error' })
    }      
  }

  const openNewFile = async (file) => {
    try {
      let name = file.name.replaceAll('.pdf', '').replaceAll('.PDF', '');
      let attbuf = await file.arrayBuffer();
      let _pdfDoc = await PDFDocument.load(attbuf);
      let n = _pdfDoc.getPageCount();
      let dims = Array(n);
      let rots = Array(n);
      for (let i = 0; i < n; i++) {
        let page = _pdfDoc.getPage(i);
        let { width, height } = page.getSize();
        dims[i] = { width, height }
        let rot = page.getRotation().angle;
        rots[i] = rot;
      }

      setDimOfPages(dims);

      setOrderOfPages([...Array(n).keys()]);
      setRotationOfPages(rots);
      setTotalPages(n);
      setFileName(name);

      setPdfDoc(_pdfDoc);

      enqueueSnackbar(`Abierto archivo ${name}.pdf`, { variant: 'success' })
    }
    catch (err) {
      enqueueSnackbar(`ERROR: ${err.message}`, { variant: 'error' })
    }
  }

  const addFile = async (file, beforePage) => {
    try {
      let attbuf = await file.arrayBuffer();
      let pdfOne = await pdfDoc.copy();
      let newOrder = [...orderOfPages];
      let n = pdfOne.getPageCount();
      let i = beforePage;
      // debugger;
      // Carga el archivo
      let pdfTwo = await PDFDocument.load(attbuf);

      let dims = [...dimOfPages];
      let rots = [...rotationOfPages];
      const copiedPages = await pdfOne.copyPages(pdfTwo, pdfTwo.getPageIndices())

      for (let page of copiedPages) {
        pdfOne.addPage(page);
        let { width, height } = page.getSize();
        let rot = page.getRotation().angle;
        // Añade la página al orden
        if (i === -1) {
          newOrder.push(n++);
          rots.push(rot);
          dims.push({
            width,
            height
          })
        }
        else {
          newOrder.splice(i, 0, n);
          rots.splice(i, 0, rot)
          dims.splice(i, 0, {
            width,
            height
          })
          i++;
          n++;
        }
      }
      // Actualiza valores

      setDimOfPages(dims);
      setRotationOfPages(rots);
      setOrderOfPages(newOrder);
      setTotalPages(n);
      setPdfDoc(pdfOne);
      enqueueSnackbar(`Añadido archivo ${file.name}`, { variant: 'success' })
    }
    catch (err) {
      enqueueSnackbar(`ERROR: ${err.message}`, { variant: 'error' })
    }
  }

  const addPage = async (beforePage) => {
    try {
      let pdfOne = await pdfDoc.copy();
      let nTotal = pdfOne.getPageCount();
      let newOrder = [...orderOfPages];
      let i = beforePage;
      if (i == -1) {
        i = totalPages
      }

      let dims = [...dimOfPages];
      let rots = [...rotationOfPages];

      let _page = pdfOne.addPage();
      const { width, height } = _page.getSize()

      newOrder.splice(i, 0, nTotal);
      rots.splice(i, 0, 0)
      dims.splice(i, 0, { width, height });

      setDimOfPages(dims);
      setRotationOfPages(rots);
      setOrderOfPages(newOrder);
      setTotalPages(prev => prev + 1);
      setPdfDoc(pdfOne);
      enqueueSnackbar(`Añadida página en blanco`, { variant: 'success' })
    }
    catch (err) {
      enqueueSnackbar(`ERROR: ${err.message}`, { variant: 'error' })
    }
  }

  const downloadElements = async () => {
    let els = elements.map(element => {
      return element.saveElement();
    })
    let str = JSON.stringify(els);
    var blob = new Blob([str], { type: "application/json;charset=utf-8" });
    saveAs(blob, `${fileName}_elementos`);
  }


  const downloadFile = async () => {
    const _pdfDoc = await PDFDocument.create();
    let pdfTwo = pdfDoc;
    const copiedPages = await _pdfDoc.copyPages(pdfTwo, orderOfPages);
    //debugger;
    for (let index = 0; index < orderOfPages.length; index++) {
      let page = copiedPages[index];
      await ElementFactory.drawPdfElements(page, elements,
        {
          canvas: refAuxCanvas.current,
          pageNumber: orderOfPages[index],
          pageIndex: index,
          totalPages,
          rotation: rotationOfPages[index],
          fonts
        })

      page.setRotation(degrees(rotationOfPages[index]))
      _pdfDoc.addPage(page);
    }

    const _pdfBytes = await _pdfDoc.save();

    var blob = new Blob([_pdfBytes], { type: "application/pdf;charset=utf-8" });
    saveAs(blob, `${fileName}_modificado.pdf`);
  }

  const downloadSelected = async () => {
    const _pdfDoc = await PDFDocument.create();
    let pdfTwo = pdfDoc;
    const copiedPages = await _pdfDoc.copyPages(pdfTwo, orderOfPages);

    for (let s = 0; s < selectedPages.length; s++) {
      let index = selectedPages[s];
      let page = copiedPages[index];
      page.setRotation(degrees(rotationOfPages[index]))
      _pdfDoc.addPage(page);
    }

    const _pdfBytes = await _pdfDoc.save();

    var blob = new Blob([_pdfBytes], { type: "application/pdf;charset=utf-8" });
    saveAs(blob, `${fileName}_extracto.pdf`);
  }

  const movePage = (dragIndex, hoverIndex) => {
    if (dragIndex === hoverIndex
      || dragIndex >= totalPages
      || hoverIndex >= totalPages
    ) {
      return;
    }
    setOrderOfPages(prev => {
      const updated = [...prev]
      const dragItem = updated[dragIndex]
      updated[dragIndex] = updated[hoverIndex]
      updated[hoverIndex] = dragItem
      return updated
    })
    setRotationOfPages(prev => {
      const updated = [...prev]
      const dragItem = updated[dragIndex]
      updated[dragIndex] = updated[hoverIndex]
      updated[hoverIndex] = dragItem
      return updated
    })
  }

  return (
    <Layout style={layoutStyle}>
      <Header style={headerStyle}>
        <HeaderPDF
          addFile={addFile}
          addPage={addPage}
          combinePages={combinePages}
          downloadElements={downloadElements}
          downloadFile={downloadFile}
          downloadSelected={downloadSelected}
          openElementsFile={openElementsFile}
          openNewFile={openNewFile}
          setShowLeftSider={setShowLeftSider}
          showLeftSider={showLeftSider}
          setShowRightSider={setShowRightSider}
          showRightSider={showRightSider}
          totalPages={totalPages}
          totalSelected={selectedPages.length}
          pageIndex={pageIndex}
          zoom={zoom}
          onChangeZoom={setZoom}
          onChangePageIndex={setPageIndex}
          widthContainer={widthLeftContainer}
          selectAll={() => {
            setSelectedPages([...Array(totalPages).keys()])
          }}
          selectNone={() => {
            setSelectedPages([])
          }}
          rotateLeft={() => {
            setRotationOfPages(prev => {
              let nr = [...prev]
              for (let i of selectedPages) {
                nr[i] = (nr[i] + 270) % 360
              }
              return nr;
            })
          }}
          deletePages={() => {
            let n = totalPages - selectedPages.length;
            if (n <= 0) return;
            let notSelecteds = [];
            for (let i = 0; i < totalPages; i++) {
              if (selectedPages.indexOf(i) === -1) notSelecteds.push(i)
            }
            let nr = [];
            for (let i = 0; i < n; i++) {
              nr.push(rotationOfPages[notSelecteds[i]])
            }
            let no = [];
            for (let i = 0; i < n; i++) {
              no.push(orderOfPages[notSelecteds[i]])
            }
            let ds = [];
            for (let i = 0; i < n; i++) {
              ds.push(dimOfPages[notSelecteds[i]])
            }

            setSelectedPages([])
            setDimOfPages(ds);
            setRotationOfPages(nr);
            setOrderOfPages(no);
            setTotalPages(n);
          }}
          rotateRight={() => {
            setRotationOfPages(prev => {
              let nr = [...prev]
              for (let i of selectedPages) {
                nr[i] = (nr[i] + 90) % 360
              }
              return nr;
            })
          }}
        />
      </Header>
      <Layout>
        {
          showLeftSider &&
          <Sider ref={refLeftContainer} width="15%" style={siderLeftStyle}>
            {pdfDoc &&
              <MiniPdfView
                dimOfPages={dimOfPages}
                pdfDoc={pdfDoc}
                onMovePage={movePage}
                elements={elements}
                orderOfPages={orderOfPages}
                rotationOfPages={rotationOfPages}
                onSelectPage={(index) => {
                  console.log('select', index)
                  setPageIndex(index)
                }}
                onRotatePage={(index, angle) => {
                  setRotationOfPages(prev => {
                    let nr = [...prev];
                    nr[index] = (nr[index] + angle + 360) % 360
                    return nr
                  })
                }}
                pageIndex={pageIndex}
                selectedPages={selectedPages}
                onChangeSelectedPages={setSelectedPages}
                totalPages={totalPages}
                widthContainer={widthLeftContainer}
              />
            }
          </Sider>
        }
        <Content style={contentStyle}>
          {pdfDoc && <MainEditorPdfView
            style={{
              width: '100%',
              overflow: 'auto',
              zIndez: 2
            }}
            attributes={attributes}
            setAttributes={setAttributes}
            elements={elements}
            setElements={setElements}
            isTablet={isTablet}
            dimensions={dimOfPages[pageIndex]}
            pageNumber={orderOfPages[pageIndex]}
            pageIndex={pageIndex}
            totalPages={totalPages}
            rotation={rotationOfPages[pageIndex]}
            pdfDoc={pdfDoc}
            zoom={zoom}
          />
          }
          <canvas
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: -1
            }}
            width={1000 * FACTOR}
            height={1000 * FACTOR}
            ref={refAuxCanvas}
          />
        </Content>
        {showRightSider &&
          <Sider width="15%" style={siderRightStyle}>
            <Attributes
              value={attributes}
              onChange={({ name, value }) => {
                setAttributes(prev => {
                  //console.log('attr', prev, name, value)
                  return {
                    ...prev,
                    [name]: value
                  }
                })
              }}
            />
          </Sider>}
      </Layout>
      <Footer style={footerStyle}><b>A.Guerrero &copy; {new Date().getFullYear()}</b></Footer>
    </Layout>
  );
};

export default EditPdf;
