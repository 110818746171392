import React, { useEffect, useState, useContext } from "react";
import { UserContext } from '../others/UserContext';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  PoweroffOutlined,
} from "@ant-design/icons";
import { Layout, Button, Dropdown, Result } from "antd";
import { Content, Footer, Header } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";

import { Route, Routes } from "react-router-dom";
import Sidebar from "./Sidebar";

const Main = ({cantView, children, header}) => {
  const [collapsed, setCollapsed] = useState(false);
  const context = useContext(UserContext);

  useEffect(() => {
    if (window.innerWidth < 426) {
      setCollapsed(true);
    }
  }, []);

  // Navigation Menu Options
  const items = [
    {
      label: "Logout",
      key: "1",
      icon: <PoweroffOutlined />,
    },
  ];

  const handleMenuClick = (e) => {
    if (e.key === "1") {
      //Logout
      localStorage.clear();
      window.location.replace("/login");
    }
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  if( !context.token ) {
    localStorage.clear();
    window.location.replace("/login");
  }

  return (
    <Layout className="h-screen w-full flex flex-row">
      <Sider
        className={
          collapsed
            ? "max-md:hidden bg-[#EBEBEB]"
            : "visible sider bg-[#EBEBEB]"
        }
        theme="light"
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{ background: "#EBEBEB" }}
      >
        <Sidebar routes={context.routes || []}/>
      </Sider>
      <Layout>
        <Header style={{ padding: 0, background: "#EBEBEB" }}>
          <div className="flex flex-row ">
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => {
                setCollapsed(!collapsed);
              }}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
                zIndex: 999,
              }}
            />
            {
              header
            }
            <Dropdown.Button
              menu={menuProps}
              icon={<UserOutlined />}
              className="flex justify-end m-4"
            >
              ¡Hola, {context.name}!
            </Dropdown.Button>
          </div>
        </Header>
        <Content className="m-[24px] p-[24px] bg-white rounded-md h-full overflow-scroll">
          {
            cantView &&
            <Result
              status={403}
              title={403}
              subTitle={"No está autorizado a ver está página"}
            />
          }
          {
            !cantView &&
            children
          }
        </Content>
        <Footer className="text-center pt-0">
          A.Guerrero &copy; {new Date().getFullYear()}
        </Footer>
      </Layout>
    </Layout>
  );
};

export default Main;
