import React from 'react';
import useMongo from './useMongo';

const useFtpBinaries = (token) => {
  const { model, columns, components, api, axios, authHeader } = useMongo({
    database: 'ftp',
    collection: '__binaries',
    columns: [],
    components: []
  });


  model.refreshData = ({ token }) => {
    if (!token) {
      return Promise.reject({
        message: 'Not logged',
        color: 'warning',
        time: 1000
      });
    }
    
    let url = `${api}_binaries`;

    return axios({
      method: 'put',
      url: url,
      responseType: 'json',
      headers: {
        [authHeader]: `Bearer ${token}`,
        'x-database': 'ftp'
      }
    });
  };

  return { model, columns, components, api }
}

export default useFtpBinaries;