import React, { useState, useEffect, useMemo, useRef } from "react";
import { Element, Rectangle, Line, Circle, KeyPoints, ElementFactory, Text, UUID, Note, Imagen, Mark } from "../hooks/Elements.js";
import { defaultAttributes } from "./Attributes";
import { Button, Dropdown, Form, Modal, Radio, Upload } from 'antd';
import Icon, {
  SelectOutlined, LineOutlined,
  FontSizeOutlined,
  BorderOutlined,
  DeleteOutlined,
  XFilled,
  FileImageOutlined
} from "@ant-design/icons";
import FlipToBackSvg from "../icons/FlipToBack.js";
import FlipToFrontSvg from "../icons/FlipToFrontSvg.js";
import CloneSvg from "../icons/CloneSvg.js";
import PolylineSvg from "../icons/PolylineSvg.js";
import { Jimp } from "jimp";
import { useSnackbar } from 'notistack';

const FlipToBackIcon = (props) => (
  <Icon component={FlipToBackSvg} {...props} />
);

const FlipToFrontIcon = (props) => (
  <Icon component={FlipToFrontSvg} {...props} />
);

const CloneIcon = (props) => (
  <Icon component={CloneSvg} {...props} />
);

const PolylineIcon = (props) => (
  <Icon component={PolylineSvg} {...props} />
);

function CanvasPDF({ attributes, height, isTablet, pageNumber, pageIndex, rotation,
  setAttributes, elements, setElements, style, totalPages, width, ...other }) {
  const [actualCursor, setActualCursor] = useState('default')
  const [fileList, setFileList] = useState([]);
  const [command, setCommand] = useState('');
  const [initialized, SetInitialized] = useState(false);

  const refCanvas = useRef(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // Inicio
  useEffect(() => {
    if (refCanvas.current) {
      //console.log('Inicio datos');
      refCanvas.current._provElements = [];
      refCanvas.current._editingElement = false;
      refCanvas.current._keyIndex = -1;
      refCanvas.current._attributes = {};
      refCanvas.current._pageNumber = pageNumber || 0;
      refCanvas.current._rotation = rotation || 0;
      refCanvas.current._elements = elements || [];
      SetInitialized(true);
    }
  }, [refCanvas.current])

  const getElements = () => {
    return refCanvas.current?._elements || []
  }

  const getProvElements = () => {
    return refCanvas.current._provElements
  }

  const setProvElements = (els) => {
    refCanvas.current._provElements = els;
  }

  const getEditingElement = () => {
    return refCanvas.current?._editingElement
  }

  const setEditingElement = (el) => {
    refCanvas.current._editingElement = el;
  }

  const getKeyIndex = () => {
    return refCanvas.current._keyIndex
  }

  const setKeyIndex = (k) => {
    refCanvas.current._keyIndex = k;
  }

  const getLocalAttributes = () => {
    return refCanvas.current._attributes
  }

  const setLocalAttributes = (attr) => {
    refCanvas.current._attributes = attr;
  }

  const saveElement = (element) => {
    setElements(prev => {
      let newEls = [...prev];
      let index = newEls.findIndex(e => e.id === element.id);
      if (index > -1) {
        //console.log('sustituyendo elem',index,element)
        newEls.splice(index, 1, element)
      }
      else {
        //console.log('añadiendo elem',element)
        newEls.push(element)
      }
      //console.log(`Hay ${newEls.length} elementos`)
      return newEls
    })
  }

  const drawActualPage = () => {
    let els = getProvElements();
    //console.log('draw page', refCanvas.current._rotation)
    ElementFactory.drawElements(els,
      {
        ...getLocalAttributes(),
        page: refCanvas.current._pageNumber,
        pageIndex: pageIndex,
        totalPages: totalPages,
        canvas: refCanvas.current,
        clearPage: true,
        rotation: refCanvas.current._rotation
      });
  }

  // Comando inicial
  useEffect(() => {
    if (initialized) {
      startCommand('select')
    }
  }, [initialized])

  useEffect(() => {
    //console.log('setrotation', rotation)
    refCanvas.current._rotation = rotation;
    drawActualPage();
  }, [rotation])

  useEffect(() => {
    //console.log('setrotation', rotation)
    refCanvas.current._elements = elements;
  }, [elements])

  useEffect(() => {
    refCanvas.current._pageNumber = pageNumber;
    let els = getProvElements();
    for (let el of els) {
      el.setPage(pageNumber);
    }
    drawActualPage()
  }, [pageNumber])

  useEffect(() => {
    //console.log('attributes',attributes)
    setLocalAttributes(attributes)
    let el = getEditingElement();
    if (el) {
      el.setAttributes(attributes)
      saveElement(el);
      drawActualPage()
    }
  }, [attributes])

  const setHandle = (handle, name, tabletName) => {
    const canvas = refCanvas.current;
    if (isTablet && tabletName) {
      canvas[tabletName] = handle;
    }
    else {
      canvas[name] = handle;
    }
  }

  const loopRedraw = () => {
    const canvas = refCanvas.current;
    if (!canvas) return;
    let els = getProvElements();
    if (els.length === 0) return;

    if (canvas.mouseHasMoved) {
      //console.log('loop', refCanvas.current._rotation)
      ElementFactory.drawElements(els,
        {
          ...getLocalAttributes(),
          page: refCanvas.current._pageNumber,
          pageIndex: pageIndex,
          totalPages: totalPages,
          canvas: refCanvas.current,
          clearPage: true,
          rotation: refCanvas.current._rotation
        })
      canvas.mouseHasMoved = false;
    }

    window.requestAnimationFrame(() => {
      loopRedraw();
    });

  };

  const getPointerPos = e => {
    const canvas = refCanvas.current;
    const rect = canvas.getBoundingClientRect();
    // use cursor pos as default
    let clientX = e.clientX;
    let clientY = e.clientY;

    // use first touch if available
    if (e.changedTouches && e.changedTouches.length > 0) {
      clientX = e.changedTouches[0].clientX;
      clientY = e.changedTouches[0].clientY;
    }

    let p = [(clientX - rect.left) / rect.width * canvas.width,
    (clientY - rect.top) / rect.height * canvas.height];
    return Element.desrotatePointPage(p, refCanvas.current._rotation, canvas);
  };

  const resetCommand = (cmd, maintainAttributes, commandAttributes) => {
    // console.log('reset', cmd, maintainAttributes);
    // debugger;
    let canvas = refCanvas.current;
    if (!maintainAttributes) {
      resetDefaultAttributes();
    }
    canvas.handleClick = false;
    canvas.handleDoubleClick = false;
    canvas.handleLeftClick = false;
    canvas.handleMouseMove = false;
    canvas.handleMouseUp = false;
    setEditingElement(false);
    setProvElements([]);
    drawActualPage();
    setActualCursor('default')

    if (cmd) {
      startCommand(cmd, commandAttributes);
    }
  }

  const replacePointToLine = (e) => {
    let pos = getPointerPos(e);
    let element = getEditingElement();
    if (!element || !element.points || element.points.length < 2) {
      return;
    }
    let n = element.points.length - 1;
    element.setPoint(n, pos);
    setHandle(true, 'mouseHasMoved');
  }

  const addPointToLine = (e) => {
    let pos = getPointerPos(e);
    let element = getEditingElement();
    if (!element || !element.points || element.points.length < 2) {
      return;
    }
    let n = element.points.length - 1;
    element.setPoint(n, pos);
    element.addPoint(pos);
  }

  const setText = (e) => {
    let pos = getPointerPos(e);
    let element = getEditingElement();
    if (!element || !element.points || element.points.length < 1) return;
    let n = element.points.length - 1;
    element.setPoint(n, pos);
    console.log('text', element)
    saveElement(element);
    resetCommand('text', true, { text: element.attributes.text, font: element.attributes.font });
  }


  const startLine = (e) => {
    //debugger;
    let pos = getPointerPos(e);
    let attr = getLocalAttributes();
    //console.log('localAttributes',attr)
    let el = new Line({
      page: refCanvas.current._pageNumber,
      lineWidth: attr.lineWidth,
      color: attr.color || '#000000',
      points: [pos, pos],
      allPages: false,
      opacity: attr.opacity
    });
    let els = getProvElements();
    setProvElements([...els, el])
    setEditingElement(el);
    setHandle(replacePointToLine, 'handleMouseMove');
    setHandle(addPointToLine, 'handleClick', 'handleMouseUp');
    setHandle(endLine, 'handleLeftClick')
    loopRedraw();
  }

  const startRect = (e) => {
    let pos = getPointerPos(e);
    let attr = getLocalAttributes();
    let el = new Rectangle({
      page: refCanvas.current._pageNumber,
      lineWidth: attr.lineWidth,
      color: attr.color || '#000000',
      points: [pos, pos],
      withFill: attr.withFill || false,
      fillColor: attr.fillColor || '#000000',
      allPages: false,
      opacity: attr.opacity
    });
    let els = getProvElements();
    setProvElements([...els, el])
    setEditingElement(el);
    setHandle(replacePointToLine, 'handleMouseMove');
    setHandle(endRect, 'handleClick');
    loopRedraw();
  }

  const endLine = (e) => {
    let els = getProvElements();
    let element = els[0];
    if (!element || !element.points || element.points.length < 2) {
      return;
    }
    element.popPoint();
    saveElement(element);
    resetCommand('line', false);
  }

  const endRect = (e) => {
    let pos = getPointerPos(e);
    let els = getProvElements();
    let element = els[0];
    if (!element || !element.points || element.points.length < 2) {
      return;
    }
    let n = element.points.length - 1;
    element.setPoint(n, pos);
    saveElement(element);
    resetCommand('rect', false);
  }

  const startText = (commandAttr) => {
    //resetCommand(undefined, true);
    let attrVals = getLocalAttributes();
    // setEditingElement({})

    let attr = Object.assign(
      {
        text: '{P} de {T}',
        color: '#000000'
      }, attrVals, commandAttr);

    console.log('attr', attr)
    let pos = [10, 10];
    let el = new Text({
      page: refCanvas.current._pageNumber,
      text: attr.text,
      color: attr.color,
      points: [pos],
      font: attr.font,
      fontSize: attr.fontSize,
      align: attr.align,
      vAlign: attr.vAlign,
      allPages: false,
      opacity: attr.opacity,
      lineHeight: attr.lineHeight,
      rotation: attr.rotation
    });
    console.log('el', el)
    setProvElements([el])
    setEditingElement(el);
    if (isTablet) {
      setHandle(false, 'handleMouseMove');
      setHandle(setText, 'handleMouseDown');
    }
    else {
      setHandle(mouseSelect, 'handleMouseMove');
      setHandle(setText, 'handleClick');
    }
    setActualCursor('default')
    loopRedraw();
  }

  const findKeyPoint = (pos, umbral) => {
    let element = getEditingElement();
    for (let index = 0; index < element.points.length; index++) {
      let point = element.points[index];
      let dist = Element.getSquaredDistance(pos, point);
      if (dist < umbral) {
        return {
          point,
          index,
          dist
        }
      }
    }
    return {
      element: false,
      index: -1,
      dist: Infinity
    }
  }

  const selectKeyPoints = (e) => {
    let pos = getPointerPos(e);
    let { point,
      index,
      dist } = findKeyPoint(pos, 25);
    if (index > -1) {
      setKeyIndex(index);
      setHandle(fixKeyPoint, 'handleClick', 'handleMouseUp');
      setHandle(moveKeyPoint, 'handleMouseMove');
      loopRedraw();
    }
  }

  const startEditingId = (id, attr, canvas) => {
    // Copiarlo a prov

    let element = getElements().find(e => e.id === id);
    if (!element) {
      return false;
    }
    setAttributes(element.attributes);
    let el = ElementFactory.cloneElement(element, attr.page);
    let prvs = [el];
    setEditingElement(el);
    let keypoints = new KeyPoints({
      color: '#0000ff',
      points: el.points,
      page: refCanvas.current._pageNumber
    })
    prvs.push(keypoints);
    setProvElements(prvs);
    setKeyIndex(-1);
    drawActualPage();
  }

  const deleteElement = (id) => {
    let index = getElements().findIndex(e => e.id === id);
    if (index === -1) {
      return;
    }
    setElements(prev => {
      let new_els = [...prev];
      new_els.splice(index, 1)
      return new_els;
    })
    setEditingElement(false)
    setProvElements([])
    drawActualPage();
  }

  const sendToBack = (elem) => {
    let index = getElements().findIndex(e => e.id === elem.id);
    if (index === -1) {
      return;
    }
    setElements(prev => {
      let new_els = [...prev];
      let el = new_els.splice(index, 1)
      new_els.unshift(el[0]);
      return new_els;
    })
    setEditingElement(false)
    setProvElements([])
    drawActualPage();
  }

  const sendToFront = (elem) => {
    let index = getElements().findIndex(e => e.id === elem.id);
    if (index === -1) {
      return;
    }
    setElements(prev => {
      let new_els = [...prev];
      let el = new_els.splice(index, 1)
      new_els.push(el[0]);
      return new_els;
    })
    setEditingElement(false)
    setProvElements([])
    drawActualPage();
  }

  const cloneElement = (elem) => {

    let clone = ElementFactory.cloneElement(elem, pageNumber, UUID())

    setElements(prev => {
      let new_els = [...prev];
      new_els.push(clone);
      return new_els;
    })

    setEditingElement(false)
    setProvElements([])
    drawActualPage();
  }

  const fixKeyPoint = (e) => {
    let pos = getPointerPos(e);
    let element = getEditingElement();
    let index = getKeyIndex();
    element.moveKeyPoint(index, pos);

    saveElement(element);

    let keypoints = new KeyPoints({
      color: '#0000ff',
      points: element.points,
      page: refCanvas.current._pageNumber
    })

    setProvElements(
      [
        element,
        keypoints
      ]
    );

    drawActualPage()

    setHandle(selectKeyPoints, 'handleClick', 'handleMouseDown');
    setHandle(false, 'handleMouseMove');
  }

  const moveKeyPoint = (e) => {
    let pos = getPointerPos(e);
    let element = getEditingElement();
    let index = getKeyIndex();
    element.moveKeyPoint(index, pos);
    setHandle(true, 'mouseHasMoved');
  }

  const findElement = ( pos, page, umbral, canvas, onlyPage) => {
    let els = getElements().filter(e => e.page === page || (!onlyPage && e.attributes.allPages));
    for (let index = els.length - 1; index >= 0; index--) {
      let element = els[index];
      let dist = element.distanceSquared(pos, canvas)
      if (dist < umbral) {
        return {
          element,
          id: element.id,
          dist
        }
      }
    }
    return {
      element: false,
      id: false,
      dist: Infinity
    }
  }

  const searchElement = (e) => {
    let pos = getPointerPos(e);
    let { element, id, dist } = findElement(pos, refCanvas.current._pageNumber, 25, refCanvas.current, false);
    if (element) {
      startEditingId(id, { page: refCanvas.current._pageNumber, pageIndex: pageIndex, totalPages: totalPages }, refCanvas.current);

      setHandle(selectKeyPoints, 'handleClick', 'handleMouseDown');

      setHandle(false, 'handleMouseMove');
    }
  }

  const mouseSelect = (e) => {
    let pos = getPointerPos(e);
    let els = getProvElements();
    if (els.length < 1) return;
    let element = els[0];
    if (!element || !element.points || element.points.length < 1) {
      return;
    }
    let n = element.points.length - 1;
    element.setPoint(n, pos);

    setHandle(true, 'mouseHasMoved');

  }

  const startSelect = () => {
    //console.log('start select')
    resetCommand(undefined, true);
    // setEditingElement({})
    let pos = [10, 10];
    let el = new Circle({
      page: refCanvas.current._pageNumber,
      lineWidth: 1,
      color: '#000000',
      points: [pos],
      radius: 5,
      allPages: false,
      opacity: 1
    });
    resetDefaultAttributes();
    setProvElements([el]);
    setEditingElement(false)
    if (isTablet) {
      setHandle(false, 'handleMouseMove');
      setHandle(searchElement, 'handleMouseDown');
    }
    else {
      setHandle(mouseSelect, 'handleMouseMove');
      setHandle(searchElement, 'handleClick', 'handleMouseUp');
    }
    setActualCursor('default')
    setHandle(true, 'mouseHasMoved');
    loopRedraw();
  }

  const commands = {
    'select': {
      start: startSelect,
      cursor: 'default',
      inmediate: true,
      icon: <SelectOutlined />,
      title: 'Selecciona elemento'
    },
    'line': {
      start: startLine,
      inmediate: false,
      cursor: 'crosshair',
      icon: <PolylineIcon />,
      title: 'Dibuja línea'
    },
    'text': {
      start: startText,
      cursor: 'default',
      inmediate: true,
      icon: <FontSizeOutlined />,
      title: 'Añade texto'
    },
    'rect': {
      start: startRect,
      cursor: 'crosshair',
      inmediate: false,
      icon: <BorderOutlined />,
      title: 'Dibuja rectángulo'
    },
  }

  const resetDefaultAttributes = () => {
    // debugger;
    let attr = {
      ...defaultAttributes
    }
    let element = getEditingElement();
    if (element) {
      attr = {
        ...attr,
        ...element.attributes
      }
    }

    //console.log('defattr',attr)
    setAttributes(attr);
  }

  const mouseSize = (e) => {
    let pos = getPointerPos(e);
    let els = getProvElements();
    if (els.length < 1) return;
    let element = els[0];
    if (!element || !element.points || element.points.length < 2) {
      return;
    }

    let [pa, pb] = Element.getRect(element.points[0], pos);
    let pc = Element.rotateLocalPoint([pb[0]-pa[0], pb[1]-pa[1]], element.attributes.rotation);
    let pd = Element.rotateLocalPoint([element.attributes.width, element.attributes.height], element.attributes.rotation);
    let scale = Math.abs(pc[0])/element.attributes.width;
    element.setAttribute('scale', scale);
    element.setPoint(0, pa);
    element.setPoint(1, [pa[0]+scale*pd[0], pa[1]+scale*pd[1]]);
    // let scale = (pos[0] - element.points[0][0]) / element.attributes.width;
    // element.setAttribute('scale',scale);
    setHandle(true, 'mouseHasMoved');
  }

  const setImage = (e) => {
    let pos = getPointerPos(e);
    let element = getEditingElement();
    // console.log('el',element)
    if (!element || !element.points || element.points.length < 2 || element.attributes.width == 0) return;

    let [pa, pb] = Element.getRect(element.points[0], pos);
    let pc = Element.rotateLocalPoint([pb[0]-pa[0], pb[1]-pa[1]], element.attributes.rotation);
    let pd = Element.rotateLocalPoint([element.attributes.width, element.attributes.height], element.attributes.rotation);
    let scale = Math.abs(pc[0])/element.attributes.width;
    element.setAttribute('scale', scale);
    element.setPoint(0, pa);
    element.setPoint(1, [pa[0]+scale*pd[0], pa[1]+scale*pd[1]]);
    
    saveElement(element);
    resetCommand('select', true);
  }

  const presetImage = (e) => {
    // debugger;
    let pos = getPointerPos(e);
    let els = getProvElements();
    if (els.length < 1) return;
    let element = els[0];
    if (!element || !element.points || element.points.length < 2) {
      return;
    }

    let [pa, pb] = Element.getRect(element.points[0], pos);
    let pc = Element.rotateLocalPoint([pb[0]-pa[0], pb[1]-pa[1]], element.attributes.rotation);
    let pd = Element.rotateLocalPoint([element.attributes.width, element.attributes.height], element.attributes.rotation);
    let scale = Math.abs(pc[0])/element.attributes.width;
    element.setAttribute('scale', scale);
    element.setPoint(0, pa);
    element.setPoint(1, [pa[0]+scale*pd[0], pa[1]+scale*pd[1]]);
    setHandle(mouseSize, 'handleMouseMove');
    setHandle(setImage, 'handleClick');
  }

  const mouseSetImage = (e) => {
    let pos = getPointerPos(e);
    let els = getProvElements();
    if (els.length < 1) return;
    let element = els[0];
    if (!element || !element.points || element.points.length < 2) {
      return;
    }
    element.setPoint(0, pos);
    let p1 = Element.rotateLocalPoint([element.attributes.width, element.attributes.height], element.attributes.rotation);
    element.setPoint(1, [pos[0] + p1[0], pos[1] + p1[1]]);
    setHandle(true, 'mouseHasMoved')
  }


  const addImage = async (file) => {
    // debugger;¡
    try {
      let attbuf = await file.arrayBuffer();

      //debugger;
      let image = await Jimp.read(attbuf);
      let b64 = await image.getBase64(image.mime);
      let img = new Image();
      img.src = b64;

      let p0 = [10, 10];
      let attr = getLocalAttributes();
      let p1 = Element.rotateLocalPoint([image.bitmap.width, image.bitmap.height], attr.rotation)

      let el = new Imagen({
        page: pageNumber,
        data: b64,
        image: img,
        points: [p0, [p0[0] + p1[0], p0[1] + p1[1]]],
        mime: image.mime,
        width: image.bitmap.width,
        height: image.bitmap.height,
        allPages: attr.allPages,
        opacity: attr.opacity,
        rotation: attr.rotation || 0,
        scale: 1
      });
      setProvElements([el])
      setEditingElement(el);
      if (isTablet) {
        setHandle(false, 'handleMouseMove');
        setHandle(presetImage, 'handleMouseDown');
      }
      else {
        setHandle(mouseSetImage, 'handleMouseMove');
        setHandle(presetImage, 'handleClick');
      }
      setHandle(true, 'mouseHasMoved');
      setActualCursor('crosshair')
      loopRedraw();
    }
    catch (err) {
      enqueueSnackbar(`ERROR: ${err.message}`, { variant: 'error' })
    }
  }

  const startCommand = (cmd, attr) => {
    // debugger;
    //console.log('Start ', cmd, elements)
    setProvElements([]);
    //resetDefaultAttributes();
    setCommand(cmd);
    let command = commands[cmd];
    if (!command) return;
    if (command.inmediate) {
      command.start(attr);
    }
    else {
      setHandle(command.start, 'handleClick', 'handleMouseDown');
    }

    if (command.cursor) {
      setActualCursor(command.cursor)
    }
  }

  const localStyle = useMemo(() => {
    return {
      ...style,
      cursor: actualCursor
    }
  }, [style, actualCursor])

  return <><canvas
    onClick={(e) => {
      if (!isTablet) {
        e.preventDefault();
        let canvas = refCanvas.current;
        if (canvas.handleClick) {
          canvas.handleClick(e);
        }
      }
    }}
    onDoubleClick={(e) => {
      if (isTablet) {
        e.preventDefault();
        let canvas = refCanvas.current;
        if (canvas.handleDoubleClick) {
          canvas.handleDoubleClick(e);
        }
        else {
          resetCommand('select', true);
        }
      }
    }}
    onContextMenuCapture={(e) => {
      if (!isTablet) {
        e.preventDefault();
        let canvas = refCanvas.current;
        if (canvas.handleLeftClick) {
          canvas.handleLeftClick(e);
        }
        else {
          resetCommand('select', true);
        }
      }
    }}
    onMouseMove={(e) => {
      if (!isTablet) {
        e.preventDefault();
        let canvas = refCanvas.current;
        if (canvas.handleMouseMove) {
          canvas.handleMouseMove(e);
        }
      }
    }}
    onTouchMove={(e) => {
      if (isTablet) {
        e.preventDefault();
        e.stopPropagation();
        let canvas = refCanvas.current;
        if (canvas.handleMouseMove) {
          canvas.handleMouseMove(e);
        }
      }
    }}
    onTouchEnd={(e) => {
      if (isTablet) {
        e.preventDefault();
        let canvas = refCanvas.current;
        if (canvas.handleMouseUp) {
          canvas.handleMouseUp(e);
        }
      }
    }}
    onTouchStart={(e) => {
      if (isTablet) {
        e.preventDefault();
        let canvas = refCanvas.current;
        if (canvas.handleMouseDown) {
          canvas.handleMouseDown(e);
        }
      }
    }}
    style={localStyle}
    height={height}
    width={width}
    ref={refCanvas}
    {...other}>
  </canvas>
    <Form
      style={{
        zIndex: 100,
        position: 'fixed',
        top: 5,
        left: 90
      }}
      className="stretchForm"
      layout="inline">
      <Form.Item layout="vertical" label={<b>Comandos</b>}>
        <Radio.Group
          value={command}
          optionType="button"
          buttonStyle="solid"
          onChange={(v) => {
            //console.log('start command',v.target.value)
            startCommand(v.target.value, false);
          }}>
          {
            Object.keys(commands).map(cmd => (
              <Radio.Button
                key={`__cmd_${cmd}`}
                type='primary'
                variant='filled'
                title={commands[cmd].title}
                value={cmd}>{commands[cmd].icon}
              </Radio.Button>
            ))
          }
        </Radio.Group>
      </Form.Item>
      <Form.Item layout="vertical" label={<b>Imagen</b>}>
        <Upload
          accept="image/jpeg,image/png"
          beforeUpload={async (e) => {
            await addImage(e);
            setFileList([])
            return false;
          }}
          fileList={fileList}
        >
          <Button type='primary'><FileImageOutlined /></Button>
        </Upload>
      </Form.Item>
      <Form.Item layout="vertical" label={<b>Elemento</b>}>
        <Button.Group>
          <Button
            type='primary'
            title="Elimina elemento"
            disabled={!getEditingElement()}
            onClick={() => {
              Modal.confirm({
                title: 'Eliminar elemento',
                content: '¿Seguro que quiere eliminar el elemento?',
                async onOk() {
                  let el = getEditingElement();
                  deleteElement(el.id);
                }
              })
            }}><DeleteOutlined />
          </Button>
          <Button
            type='primary'
            title="Envía al fondo"
            disabled={!getEditingElement()}
            onClick={() => {
              let el = getEditingElement();
              sendToBack(el);
            }}><FlipToBackIcon />
          </Button>
          <Button
            type='primary'
            title="Envía al frente"
            disabled={!getEditingElement()}
            onClick={() => {
              let el = getEditingElement();
              sendToFront(el);
            }}><FlipToFrontIcon />
          </Button>
          <Button
            type='primary'
            title="Clona elemento"
            disabled={!getEditingElement()}
            onClick={() => {
              let el = getEditingElement();
              cloneElement(el);
            }}><CloneIcon />
          </Button>
        </Button.Group>
      </Form.Item>
    </Form>
  </>
}

export default CanvasPDF;