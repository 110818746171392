import React, { useContext, useEffect, useState, useRef, useMemo, useCallback } from "react";
import useElements from "../hooks/useElements.js";
import { Element, Rectangle, Line, Circle, KeyPoints, ElementFactory, Text, Note, Imagen, Mark } from "../hooks/Elements.js";
import CanvasPDF from "./CanvasPDF.js";
import CanvasSimplePDF from "./CanvasSimplePDF.js";
import PdfView from './PdfView.js'

const FACTOR = 72 / 25.4;

const MainEditorPdfView = ({ attributes, dimensions, elements, fonts, isTablet, 
  pageIndex, pageNumber, pdfDoc, rotation, setElements, setAttributes, totalPages, zoom }) => {

  const realDimensions = useMemo(() => {
    if (rotation === 90 || rotation === 270) {
      return {
        width: dimensions.height,
        height: dimensions.width
      }
    }
    return dimensions
  }, [rotation, dimensions])

  return <div
    style={{
      position: 'relative',
      top: 0, left: 0, zIndex: 3,
      width: 'fit-content',
      height: 'fit-content',
    }}>
    <CanvasPDF
      attributes={attributes}
      setAttributes={setAttributes}
      setElements={setElements}
      isTablet={isTablet}
      readOnly={false}
      elements={elements}
      width={realDimensions.width}
      height={realDimensions.height}
      rotation={rotation}
      pageNumber={pageNumber}
      pageIndex={pageIndex}
      totalPages={totalPages}
      style={{
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 120,
        zIndex: 5,
        top: 20, left: 20,
        width: `${realDimensions.width * zoom * FACTOR}px`,
        height: `${realDimensions.height * zoom * FACTOR}px`
      }}/>
    <CanvasSimplePDF
      attributes={attributes}
      isTablet={isTablet}
      elements={elements}
      readOnly={true}
      width={realDimensions.width}
      height={realDimensions.height}
      pageNumber={pageNumber}
      pageIndex={pageIndex}
      totalPages={totalPages}
      rotation={rotation}
      style={{
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 120,
        zIndex: 4,
        top: 20, left: 20,
        width: `${realDimensions.width * zoom * FACTOR}px`,
        height: `${realDimensions.height * zoom * FACTOR}px`
      }}></CanvasSimplePDF>
    {pdfDoc &&
      <PdfView
        style={{ position: 'absolute', top: 0, left: 0, zIndex: 2 }}
        dimensions={dimensions}
        pdfDoc={pdfDoc}
        pageNumber={pageNumber}
        rotation={rotation}
        zoom={zoom}
      />
    }
  </div>
}

export default MainEditorPdfView;