const FlipToBackSvg = () => (<svg width="1em" height="1em" viewBox="0 0 512 512">
  <path fill="var(--ci-primary-color, #ffffff)" d="M64,496H400V464H64a16.019,16.019,0,0,1-16-16V120H16V448A48.055,48.055,0,0,0,64,496Z" className="ci-primary"/>
  <rect width="32" height="32" x="464" y="368" fill="var(--ci-primary-color, #ffffff)" className="ci-primary"/>
  <rect width="29.091" height="32" x="173.091" y="368" fill="var(--ci-primary-color, #ffffff)" className="ci-primary"/>
  <rect width="29.091" height="32" x="405.818" y="368" fill="var(--ci-primary-color, #ffffff)" className="ci-primary"/>
  <rect width="29.091" height="32" x="231.272" y="368" fill="var(--ci-primary-color, #ffffff)" className="ci-primary"/>
  <rect width="29.091" height="32" x="289.455" y="368" fill="var(--ci-primary-color, #ffffff)" className="ci-primary"/>
  <rect width="29.091" height="32" x="347.637" y="368" fill="var(--ci-primary-color, #ffffff)" className="ci-primary"/>
  <rect width="32" height="32" x="112" y="368" fill="var(--ci-primary-color, #ffffff)" className="ci-primary"/>
  <rect width="32" height="29.091" x="112" y="135.272" fill="var(--ci-primary-color, #ffffff)" className="ci-primary"/>
  <rect width="32" height="29.091" x="112" y="77.091" fill="var(--ci-primary-color, #ffffff)" className="ci-primary"/>
  <rect width="32" height="29.091" x="112" y="309.818" fill="var(--ci-primary-color, #ffffff)" className="ci-primary"/>
  <rect width="32" height="29.091" x="112" y="251.637" fill="var(--ci-primary-color, #ffffff)" className="ci-primary"/>
  <rect width="32" height="29.091" x="112" y="193.455" fill="var(--ci-primary-color, #ffffff)" className="ci-primary"/>
  <rect width="32" height="32" x="112" y="16" fill="var(--ci-primary-color, #ffffff)" className="ci-primary"/>
  <rect width="29.091" height="32" x="289.454" y="16" fill="var(--ci-primary-color, #ffffff)" className="ci-primary"/>
  <rect width="29.091" height="32" x="231.272" y="16" fill="var(--ci-primary-color, #ffffff)" className="ci-primary"/>
  <rect width="29.091" height="32" x="405.818" y="16" fill="var(--ci-primary-color, #ffffff)" className="ci-primary"/>
  <rect width="29.091" height="32" x="173.091" y="16" fill="var(--ci-primary-color, #ffffff)" className="ci-primary"/>
  <rect width="29.091" height="32" x="347.637" y="16" fill="var(--ci-primary-color, #ffffff)" className="ci-primary"/>
  <rect width="32" height="32" x="464" y="16" fill="var(--ci-primary-color, #ffffff)" className="ci-primary"/>
  <rect width="32" height="29.091" x="464" y="309.818" fill="var(--ci-primary-color, #ffffff)" className="ci-primary"/>
  <rect width="32" height="29.091" x="464" y="77.091" fill="var(--ci-primary-color, #ffffff)" className="ci-primary"/>
  <rect width="32" height="29.091" x="464" y="193.454" fill="var(--ci-primary-color, #ffffff)" className="ci-primary"/>
  <rect width="32" height="29.091" x="464" y="135.272" fill="var(--ci-primary-color, #ffffff)" className="ci-primary"/>
  <rect width="32" height="29.091" x="464" y="251.637" fill="var(--ci-primary-color, #ffffff)" className="ci-primary"/>
</svg>);

export default FlipToBackSvg;