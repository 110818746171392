import React, { useEffect } from "react";
import { Menu } from "antd";
import { LogoL } from "../assets";
import { Link } from "react-router-dom";

const Sidebar = ({routes}) => {
  let path = window.location.pathname;

  useEffect(() => {}, []);

  const items = routes.map( (route, index) => {
    return {
      key: `R-${index}`,
      icon: route.icon,
      label: <Link to={route.path}>{route.label}</Link>
    }
  }) 

  const selectedKey = () => {
    let found = routes.findIndex(r => r.path == path);
    return found==-1 ? 'R-0' : `R-${found}`;
  };

  return (
    <>
      <div className="flex flex-col w-full justify-center items-center py-4 bg-[#EBEBEB]">
        <img src={LogoL} alt="logo" className="w-16" />
      </div>
      <Menu
        theme="light"
        mode="inline"
        defaultSelectedKeys={[selectedKey()]}
        className="text-base font-normal text-black bg-[#EBEBEB]"
        items={items}
      />
    </>
  );
};

export default Sidebar;
