import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { Dashboard, NotFound, CHG, Home, Scanner, EditPdf } from "./pages";
import useAuth from "./hooks/useAuth";
import { UserContext } from "./others/UserContext";
import { LineChartOutlined, HomeOutlined, ScanOutlined, FilePdfOutlined, GithubOutlined } from "@ant-design/icons";
import IconMap from "./icons/IconMap";
import BigIconMap from "./icons/BigIconMap";
import Colors from './colors.json';

const routes = [
  {
    exact: true,
    path: '/',
    element: <Home />,
    icon: <HomeOutlined style={{
      fontSize: 36
    }} />,
    label: 'Inicio',
    bigIcon: <HomeOutlined style={{
      fontSize: 72,
      backgroundColor: Colors.primary.background,
      color: Colors.primary.foreground
    }} />,
  },
  {
    exact: true,
    path: '/dashboard',
    element: <Dashboard />,
    icon: <LineChartOutlined
      style={{
        fontSize: 36
      }}
    />,
    label: 'Gráficas',
    bigIcon: <LineChartOutlined
      style={{
        fontSize: 72,
        backgroundColor: Colors.primary.background,
        color: Colors.primary.foreground
      }}
    />,
  },
  {
    exact: true,
    path: '/chg',
    element: <CHG />,
    icon: IconMap,
    label: 'Mapa',
    bigIcon: BigIconMap
  },
  {
    exact: true,
    path: '/scan',
    element: <Scanner />,
    icon: <ScanOutlined style={{
      fontSize: 36
    }}
    />,
    label: 'Escáner',
    bigIcon: <ScanOutlined
    style={{
      fontSize: 72,
      backgroundColor: Colors.primary.background,
      color: Colors.primary.foreground
    }}
  />
  },
  {
    exact: true,
    path: '/pdf',
    element: <EditPdf />,
    icon: <FilePdfOutlined style={{
      fontSize: 36
    }}
    />,
    label: 'Editor PDF',
    bigIcon: <FilePdfOutlined
    style={{
      fontSize: 72,
      backgroundColor: Colors.primary.background,
      color: Colors.primary.foreground
    }}
  />
  },
  {
    exact: true,
    path: '/gogs',
    icon: <GithubOutlined style={{
      fontSize: 36
    }}
    />,
    label: 'Gogs',
    bigIcon: <GithubOutlined
    style={{
      fontSize: 72,
      backgroundColor: Colors.primary.background,
      color: Colors.primary.foreground
    }}
  />
  }


  
]

function App() {
  const [userData, setUserData] = useState({
    loading: true,
    token: localStorage.getItem('agm-token'),
    routes: routes
  })


  const { AuthLogin, checkToken } = useAuth({ site: 'production' })

  function logout() {
    localStorage.removeItem('agm-token');
    setUserData({
      authed: false,
      user: '',
      token: '',
      logout: logout,
      routes: routes
    })
  }

  useEffect(() => {
    if (userData.token) {
      localStorage.setItem('agm-token', userData.token);
    }

    checkToken({
      token: userData.token,
      setData: setUserData,
      logout: logout,
      routes: routes
    });
  }, [userData.token]);


  if (userData.loading) {
    return <div>Is loading...</div>
  }
  return (
    <UserContext.Provider value={userData}>
      <Routes>
        <Route exact path="/login" element={<AuthLogin logout={logout} setData={setUserData} />} />
        {userData.token !== null &&
          routes.map((route, index) => {
            return <Route
              key={`R-${index}`}
              exact={route.exact}
              path={route.path}
              element={route.element} />
          })
        }
        {userData.token === null &&
          <Route exact path="*" element={<NotFound />} />
        }
        <Route path="*" element={<NotFound />} />
        {/* <Route path="/500" element={<SomethingWentWrong />} /> */}
      </Routes>
    </UserContext.Provider>
  );
}

export default App;
