import React, { useState, useEffect, useMemo } from "react";
import PropTypes from 'prop-types';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

import './pdf.css';

const FACTOR = 72 / 25.4;

function PdfView({ dimensions, docAttributes, pdfDoc, onDocumentLoadSuccess, onPageLoadSuccess, 
  pageAttributes, pageNumber, rotation, zoom, ...other }) {
  const [pdfBytes, setPdfBytes] = useState(false)
  
  useEffect(() => {
    async function fetchBytes() {
      let _pdfbytes = await pdfDoc.save();
      setPdfBytes({ data: _pdfbytes });
    }

    if (pdfDoc)
      fetchBytes();
  }, [pdfDoc])

  const realWidth = useMemo( () => {
    if(rotation===90 || rotation===270) return dimensions.height;
    return dimensions.width;
  },[ rotation, dimensions])

  return <div className="container_document" {...other}>
  {pdfBytes &&
      <Document 
        {...docAttributes}
        file={pdfBytes} 
        onLoadSuccess={onDocumentLoadSuccess}>
        <Page
          {...pageAttributes}
          width={realWidth*FACTOR}
          scale={zoom}
          rotate={rotation}
          onLoadSuccess={onPageLoadSuccess}
          pageNumber={pageNumber + 1}
        />
      </Document>
    }
</div>
}

PdfView.propTypes = {
  docAttributes: PropTypes.object,
  pdfDoc: PropTypes.any,
  pdfBytes: PropTypes.any,
  onLoadSuccess: PropTypes.func,
  onPageLoadSuccess: PropTypes.func,
  pageNumber: PropTypes.number,
  pageAttributes: PropTypes.object,
  zoom: PropTypes.number
};

export default PdfView;