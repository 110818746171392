import React, { useEffect, useRef } from "react";
import { ElementFactory } from "../hooks/Elements.js";

function CanvasSimplePDF({ attributes, elements, height, isTablet, pageNumber, pageIndex, 
  rotation, totalPages, width, ...other }) {

  const refCanvas = useRef(null);

  const drawActualPage = () => {
    ElementFactory.drawElements(elements, 
      {
        ...attributes, 
        page: pageNumber,
        pageIndex: pageIndex,
        totalPages: totalPages,
        canvas: refCanvas.current,
        clearPage: true, 
        rotation
      });
  }

  useEffect(() => {
    drawActualPage();
  }, [attributes, refCanvas.current, elements, rotation, pageNumber, totalPages])

  return <canvas
    height={height}
    width={width}
    ref={refCanvas}
    {...other}>
  </canvas>
}

export default CanvasSimplePDF;