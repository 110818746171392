import React from "react";
import axios from 'axios';

const useSite = (props) => {

  let { database, site = 'production' } = props;

  var Sites = {
    'develop': {
      authHeader: 'Authorization',
      base: `http://localhost:40000/`,
      api: `http://localhost:40000/`
    },
    'production': {
      authHeader: 'Authorization',
      base: `https://www.gebrais.es/`,
      api: `https://api.gebrais.es/`
    }
  }

  const axiosInstance = axios.create();

  return {
    ...Sites[site],
    axios: axiosInstance,
    site,
    database
  };
};

export default useSite;
