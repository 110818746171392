import React, { useRef, useMemo } from "react";
import { Page } from 'react-pdf';
import { useDrag, useDrop } from 'react-dnd'
import {
  BorderOutlined, CheckSquareOutlined,
  RotateLeftOutlined,
  RotateRightOutlined
} from "@ant-design/icons";
import CanvasSimplePDF from "./CanvasSimplePDF";
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import Colors from '../colors.json';
import './pdf.css';
import { Button } from "antd";

const FACTOR = 72 / 25.4;
const SIZE = 20;

const MiniaturePDF = ({ dimPage, elements, isActual, isSelected, onAddSelectedPage, maxWidth,
  onMovePage, onSelectPage, onRotatePage, pageIndex, pageNumber, rotation, 
  totalPages, widthContainer, ...other }) => {

  const [{ isDragging }, dragRef] = useDrag({
    type: 'miniature',
    item: { pageIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const [spec, dropRef] = useDrop({
    accept: 'miniature',
    hover: (item, monitor) => {
      const dragIndex = item.pageIndex
      const hoverIndex = pageIndex

      const hoverBoundingRect = (ref.current ? ref.current.getBoundingClientRect() : null);
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top

      // if dragging down, continue only when hover is smaller than middle Y
      if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return
      // if dragging up, continue only when hover is bigger than middle Y
      if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return

      onMovePage(dragIndex, hoverIndex)
      item.pageIndex = hoverIndex
    },
  })

  const ref = useRef(null)
  const dragDropRef = dragRef(dropRef(ref))

  const onPageClick = (e, pageIndex) => {
    console.log('page click)')
    e.stopPropagation();
    if (onSelectPage) {
      onSelectPage(pageIndex);
    }
  }

  function getColor(isActual, isSelected) {
    if (isActual) return Colors.info.background;
    return Colors.secondary.background
  }

  const realDimensions = useMemo(() => {
    if (rotation === 90 || rotation === 270) {
      return {
        width: dimPage.height,
        height: dimPage.width
      }
    }
    return dimPage
  }, [rotation, dimPage])

  const factorScale = useMemo(() => {
    return widthContainer / (maxWidth + SIZE + 20) / FACTOR * 0.8;
  }, [maxWidth, widthContainer])

  return <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Button
        type='primary'
        icon={<RotateRightOutlined/>}
        onClick={(e) => {
          e.stopPropagation();
          if (onRotatePage) {
            onRotatePage(pageIndex,90)
          }
        }}
      />
      <div
        style={{
          width: SIZE,
          marginLeft: 5,
          marginRight: 0,
          marginTop:'1em',
          marginBottom:'1em',
          fontSize: SIZE,
          cursor: 'pointer'
        }}
        onClick={(e) => {
          e.stopPropagation();
          if (onAddSelectedPage) {
            onAddSelectedPage(pageIndex)
          }
        }}
      >{isSelected ? <CheckSquareOutlined /> : <BorderOutlined />}
      </div>
      <Button
        type='primary'
        icon={<RotateLeftOutlined/>}
        onClick={(e) => {
          e.stopPropagation();
          if (onRotatePage) {
            onRotatePage(pageIndex,-90)
          }
        }}
      />
    </div>
    <div
      key={`page_${pageIndex + 1}`}
      ref={dragDropRef}
      className='miniature'
      style={{
        position: 'relative',
        top: 0, left: 0,
        width: `${realDimensions.width * factorScale * FACTOR + 20}px`,
        height: '100%',
        backgroundColor: getColor(isActual, isSelected)
      }}>
      <div
        onClick={(e) => {
          onPageClick(e, pageIndex);
        }}
        style={{
          cursor: 'pointer',
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: 80 * factorScale / 0.06,
          zIndex: 4,
          top: 0, left: 0,
          width: '100%', height: '100%',
          color: 'rgba(0,0,0,0.2)',
          transform: `rotate(${rotation}deg)`
        }}>{pageIndex + 1}</div>
      <CanvasSimplePDF 
          style={{
            position: 'absolute',
            zIndex: 3,
            top: 10, 
            left: 10,
            width: `${realDimensions.width * factorScale * FACTOR}px`, 
            height: `${realDimensions.height*factorScale*FACTOR}px`,
          }}
          elements={elements}
          pageNumber={pageNumber}
          pageIndex={pageIndex}
          totalPages={totalPages}
          rotation={rotation}
          width={realDimensions.width}
          height={realDimensions.height}
        />
      <Page
        style={{
          position: 'absolute', top: 0, left: 0, zIndex: 2,
          width: `${realDimensions.width * factorScale}px`, height: '100%'
        }}
        pageNumber={pageNumber + 1}
        rotate={rotation}
        width={realDimensions.width * FACTOR}
        scale={factorScale}
      />
    </div>
  </div>
}

export default MiniaturePDF;